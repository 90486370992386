import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Sidebar from '../components/navbar';
import { FaSun, FaFireAlt, FaHistory, FaThumbsDown, FaHome, FaFire, FaThermometerHalf, FaTimesCircle, FaBan, FaReply } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import arrowright from '../icons/arrow_right.svg';
import loading from '../icons/loading.gif';

import add from '../icons/add.svg';
import ticked from '../icons/OIP.png';
import { Modal, Button } from 'react-bootstrap'
import Menu from '../components/menu';
import upload from '../icons/upload.svg';
import * as XLSX from 'xlsx';
import API_BASE_URL from '../components/config';
function Exupload() {
  const [fileData, setFileData] = useState(null);
  const [laststeps, setSteps] = useState(null);
  const [list, setList] = useState('');
  const [tags, setTags] = useState('');
  const [processing, setProcessing] = useState(false);
  const [loadingchk, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [tagsucc, setTagsucc] = useState('');
  
  const [columnMappings, setColumnMappings] = useState([]);
  const [columnNames, setColumnNames] = useState(null);
  const [selectedList, setSelectedList] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [csvData, setCsvData] = useState([]);

  const [showModal, setShowModal] = useState(false); 
  const [showsecondModal, setsecShowModal] = useState(false); 
  
  const [id, setUserid] = useState('');
  const [listname, setListname] = useState('');
  const [tagname, setTagname] = useState('');
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false)
  const secModalOpen = () => setsecShowModal(true);
  const secleModalClose = () => setsecShowModal(false)
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     const data = new Uint8Array(e.target.result);
  //     const workbook = XLSX.read(data, { type: 'array' });
  //     const sheet = workbook.Sheets[workbook.SheetNames[0]]; // Assuming only one sheet

  //     // Get column and row counts
  //     const range = XLSX.utils.decode_range(sheet['!ref']);
  //     const numCols = range.e.c + 1;
  //     const numRows = range.e.r + 1;
  //     const jsonData = XLSX.utils.sheet_to_json(sheet);
  //     setCsvData(jsonData);
  //     // Get column names
  //     try{
  //       const columnNames = [];
  //       for (let i = 0; i < numCols; i++) {
  //         const cell = sheet[XLSX.utils.encode_cell({ r: 0, c: i })];
  //         const columnName = cell ? cell.v : `Column ${i + 1}`;
  //         if (!columnName.match(/^Column\s+\d+$/)) {
  //           // Exclude columns with numeric names
  //           columnNames.push(columnName);
  //         }
  //       }
  //     }catch(error){
  //       alert('Please Upload Another File')
  //     }
      
     

  //     const fileName = file.name;
  //     // Store file details in state
  //     setFileData({ fileName, numRows, numCols });
  //     setColumnNames(columnNames);
  //     // Set column mappings with initial values
  //     const initialColumnMappings = columnNames.map(columnName => ({
  //       columnName,
  //       importOption: '',
  //     }));
  //     setColumnMappings(initialColumnMappings);
  //   };

  //   reader.readAsArrayBuffer(file);
  // };
  const handleFileChange = (event) => {
    setLoading(true)
    const file = event.target.files[0];
  
   
    if (!file || !file.name.endsWith('.xlsx') && !file.name.endsWith('.csv')) {
    setLoading(false)

      alert('Please upload an Excel file.');

      return;
    }
  
    const reader = new FileReader();
  
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]]; // Assuming only one sheet
  
        // Get column and row counts
        const range = XLSX.utils.decode_range(sheet['!ref']);
        const numCols = range.e.c + 1;
        const numRows = range.e.r + 1;
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setCsvData(jsonData);
  
        // Get column names
        const columnNames = [];
        for (let i = 0; i < numCols; i++) {
          const cell = sheet[XLSX.utils.encode_cell({ r: 0, c: i })];
          const columnName = cell ? cell.v : `Column ${i + 1}`;
          if (!columnName.match(/^Column\s+\d+$/)) {
            // Exclude columns with numeric names
            columnNames.push(columnName);
          }
        }
  
        const fileName = file.name;
        // Store file details in state
        setFileData({ fileName, numRows, numCols });
        setColumnNames(columnNames);
        // Set column mappings with initial values
        const initialColumnMappings = columnNames.map(columnName => ({
          columnName,
          importOption: '',
        }));
        setColumnMappings(initialColumnMappings);
    setLoading(false)

      } catch (error) {
    setLoading(false)

        alert('Error reading column names. Please upload another file.');
      }
    };
  
    reader.readAsArrayBuffer(file);
  };
  
  const handleDelete = () => {
    // Reset fileData state and column mappings
    setFileData(null);
    setColumnMappings([]);
    setSelectedList([])
    setSelectedTag([])
    setCsvData([])
  };
  // const changestep = () => {
  //   setSteps(true);
  //   // const gg =document.querySelector('.LST-TAG');
  //   // setTimeout(() => {
  //   //   gg.style.transition = 'transform 0.3s ease-in-out'; // Set transition properties
  //   //   gg.style.transform = 'translateX(0)'; // Apply transform after delay
  //   // }, 1000);
  // };
  const changestep = () => {
    // Ensure required fields are mapped
    const requiredFields = ['firstname', 'phone'];
    const mappedFields = columnMappings.map((mapping) => mapping.importOption);

    if (requiredFields.every((field) => mappedFields.includes(field))) {
      setSteps(true);
    } else {
      alert('Please map all required fields before proceeding.');
    }
  };
  const revstep = () => {
    setSteps(null);
  };
  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(null);
  useEffect(() => {
    const fetchusertags = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
    

        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-tag`, {
            id
          });
          setTags(response.data);
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchusertags(); 
  }, []);
  useEffect(() => {
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
    const fetchwebhooks = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)
        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-lists`, {
            id
          });
          setList(response.data);
          console.log(response.data)
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchwebhooks(); 
  }, []);
  const addlist = async (listname,event) => {
    setProcessing(true)
    event.preventDefault();

    try {
      // Send POST request to add a list
      const response = await axios.post(`${API_BASE_URL}/api/add-list`, { id, listname });
      
      setProcessing(false)
      setsecShowModal(false);
      const newlist = {
        id: response.data,
        listname: listname, 
    
      };
      const lists = list ? [...list, newlist] : [newlist];
      setList(lists);
      setSelectedList(listname)
    } catch (error) {
      setProcessing(false)

      console.error('Error adding list:');
    }
  };
  const addtag = async (tagname,description,event) => {
    setProcessing(true)
    setTagsucc(false)
    event.preventDefault();

    try {
      // Send POST request to add a list
      const response = await axios.post(`${API_BASE_URL}/api/add-tag`, { id, tagname,description });
      
      setProcessing(false)
      setShowModal(false);
      const newtag = {
        id: response.data,
        tagname: tagname, 
        description:description,
    
      };
      const taghs = tags ? [...tags, newtag] : [newtag];
      setTags(taghs);
      setSelectedTag(tagname)
    } catch (error) {
      setProcessing(false)

      console.error('Error adding list:');
    }
  };
  const getDisabledOptions = () => {
    const selectedOptions = columnMappings.map(mapping => mapping.importOption);
    return selectedOptions.filter(option => option !== 'do_not_import');
  };
  const handleChange = (event, index) => {
    const selectedOption = event.target.value;
    const newColumnMappings = [...columnMappings];
    
    // Check if the selected mapping is already used
    if (selectedOption !== 'do_not_import' && newColumnMappings.some((mapping, i) => mapping.importOption === selectedOption && i !== index)) {
      alert(`${selectedOption} is already selected. Please choose another.`);
      return;
    }

    newColumnMappings[index].importOption = selectedOption;
    setColumnMappings(newColumnMappings);

    const targetRow = event.target.closest('.row');
    const targetInput = targetRow.querySelector('.mappedInput');
    const targetCol1 = targetRow.querySelector('.col-1 img');

    if (selectedOption === "do_not_import") {
      targetInput.style.backgroundColor = '';
      targetCol1.style.display = 'none';
      targetInput.classList.remove('select_col');
    } else {
      targetInput.classList.add('select_col');
      targetCol1.style.display = 'block';
    }
  };
  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber == null) {
        return null; // Return null for null or undefined values
    }

    const digits = String(phoneNumber).replace(/\D/g, ''); // Ensure phoneNumber is a string and remove non-digit characters
    const countryCode = '+1';

    if (String(phoneNumber).startsWith('+1')) {
      // alert(phoneNumber)
        // If the number is already in international format with country code +1, keep it as is
        return phoneNumber;
    } else if (digits.length === 10) {
        // Add +1 to 10 digit numbers
        return countryCode + digits;
    } else if (digits.length > 10) {
        // Add +1 to numbers with more than 10 digits
        return countryCode + digits.slice(-10);
        // return countryCode + digits.slice(0);
    } else {
        return null; // Invalid phone number format
    }
};
//   const formatPhoneNumber = (phoneNumber) => {
//     const digits = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters
//     if (digits.length === 10) {
//         return '+1' + digits;
//     } else if (digits.length > 10) {
//         return '+1' + digits.slice(0, 3) + '...' + digits.slice(-4);
//     } else {
//         return null; // Invalid phone number format
//     }
// };

const handleUpload = () => {
    setLoading(true);
    // if (!selectedList) {
    //     setLoading(false);
    //     alert('Please choose a list before uploading.');
    //     return;
    // }

    const phoneColumnMapping = columnMappings.find(mapping => mapping.importOption.toLowerCase() === 'phone');
    const firstNameColumnMapping = columnMappings.find(mapping => mapping.importOption.toLowerCase() === 'firstname');
    
    if (!phoneColumnMapping || !firstNameColumnMapping) {
        setLoading(false);
        console.error('Required column mapping not found');
        return;
    }
    
    const phoneColumnName = phoneColumnMapping.columnName;
    const firstNameColumnName = firstNameColumnMapping.columnName;

    const uniquePhoneNumbers = new Set();
    
    const formattedCsvData = csvData
        .map(row => {
            const phoneNumber = row[phoneColumnName];
            const firstName = row[firstNameColumnName];
            
            if (!phoneNumber || !firstName) {
                return null;
            }
            
            if (uniquePhoneNumbers.has(phoneNumber)) {
                return null;
            }
            
            uniquePhoneNumbers.add(phoneNumber);
            
            row[phoneColumnName] = formatPhoneNumber(phoneNumber);
            return row;
        })
        .filter(Boolean);

    const BATCH_SIZE = 2000;
    const totalBatches = Math.ceil(formattedCsvData.length / BATCH_SIZE);

    const postData = {
        userid: id,
        columnMappings,
        selectedList,
        selectedTag,
    };

    const sendBatch = async (batchNumber) => {
        const start = batchNumber * BATCH_SIZE;
        const end = start + BATCH_SIZE;
        const batchData = formattedCsvData.slice(start, end);
      
        if (batchData.length > 0) {
            postData.csvData = batchData;

            try {
                await axios.post(`${API_BASE_URL}/api/upload-excel`, postData);
                if (batchNumber < totalBatches - 1) {
                    sendBatch(batchNumber + 1);
                } else {
                    setLoading(false);
                    alert('File uploaded successfully');
                    navigate("/crm");
                }
            } catch (error) {
                setLoading(false);
                console.error('Error uploading batch:', error);
                alert('Error uploading file. Please try again.');
            }
        } else {
            setLoading(false);
            alert('File uploaded successfully');
            navigate("/crm");
        }
    };

    sendBatch(0);
};

  // const handleUpload = () => {
  //   setLoading(true)
  //   if (!selectedList) {
  //   setLoading(false)

  //     alert('Please choose a list before uploading.');
      
  //     return;
  //   }
  //   // const phoneColumnMapping = columnMappings.find(mapping => mapping.importOption.toLowerCase() === 'phone');
  //   //     if (!phoneColumnMapping) {
  //   // setLoading(false)

  //   //         console.error('Phone column mapping not found');
            
  //   //         return;
  //   //     }
  //   //     const phoneColumnName = phoneColumnMapping.columnName;
  //   //     const formattedCsvData = csvData.map(row => {
  //   //       const phoneNumber = row[phoneColumnName];
  //   //       if (phoneNumber) {
  //   //           row[phoneColumnName] = formatPhoneNumber(phoneNumber);
  //   //           return row;
  //   //       }
  //   //       return null; // Return null for rows with empty phone numbers
  //   //   }).filter(Boolean); // Filter out rows with null (empty phone numbers)
  //   const phoneColumnMapping = columnMappings.find(mapping => mapping.importOption.toLowerCase() === 'phone');
  //   const firstNameColumnMapping = columnMappings.find(mapping => mapping.importOption.toLowerCase() === 'firstname');
  //   // const lastNameColumnMapping = columnMappings.find(mapping => mapping.importOption.toLowerCase() === 'lastname');
  //   // alert(firstNameColumnMapping)
  //   if (!phoneColumnMapping || !firstNameColumnMapping ) {
  //       setLoading(false);
  //       console.error('Required column mapping not found');
  //       return;
  //   }
    
  //   const phoneColumnName = phoneColumnMapping.columnName;
  //   const firstNameColumnName = firstNameColumnMapping.columnName;
  //   // const lastNameColumnName = lastNameColumnMapping.columnName;
    
  //   const uniquePhoneNumbers = new Set();
    
  //   const formattedCsvData = csvData
  //       .map(row => {
  //           const phoneNumber = row[phoneColumnName];
  //           const firstName = row[firstNameColumnName];
  //           // const lastName = row[lastNameColumnName];
            
  //           // Check if the phone number, first name, or last name is empty
  //           if (!phoneNumber || !firstName ) {
  //               return null;
  //           }
            
  //           // Check for duplicate phone numbers
  //           if (uniquePhoneNumbers.has(phoneNumber)) {
  //               return null;
  //           }
            
  //           uniquePhoneNumbers.add(phoneNumber);
            
  //           // Format the phone number
  //           row[phoneColumnName] = formatPhoneNumber(phoneNumber);
  //           return row;
  //       })
  //       .filter(Boolean); // Filter out rows that are null
    
  //     // alert(uniquePhoneNumbers)
  //     // console.log(csvData)
  //   const postData = {
  //     userid:id,
  //     csvData:formattedCsvData,
  //     columnMappings,
  //     selectedList,
  //     selectedTag,
  //   };

  //   axios.post(`${API_BASE_URL}/api/upload-excel`, postData)
  //     .then(response => {
  //       setLoading(false)

  //       alert('File uploaded successfully')
  //       navigate("/crm");

  //     })
  //     .catch(error => {
  //           setLoading(false)

  //     });
  // };
// function handleChange  (event, index)  {
//     const selectedOption = event.target.value;
//     // alert(selectedOption)

//     const targetRow = event.target.closest('.row');
//     const targetInput = targetRow.querySelector('.mappedInput');
   
//     const targetCol1 = targetRow.querySelector('.col-1 img'); // Select the image element
    
//     // const targetColumn = targetRow.querySelector('.targetColumn').value;

//     if (selectedOption === "do_not_import") {
//       // targetInput.value = ""; // Clear input value
//       targetInput.style.backgroundColor = ''; // Remove background color
//       targetCol1.style.display = 'none';
//       targetInput.classList.remove('select_col'); // Remove 'Yes' text
//     } else {
//       // targetInput.value = selectedOption; // Set input value
//       targetInput.classList.add('select_col'); // Change input field color
//       targetCol1.style.display = 'block'; // Add 'Yes' to the div
      
//       // Here you can send the selectedOption and targetColumn values to the server for processing
//       // console.log("Selected Option: " + selectedOption);
//       // console.log("Target Column: " + targetColumn);
//     }
//   };
  if (!authenticated) {
    return null;
  } else {
    return (
      <Sidebar>
        {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
 <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => addtag(tagname, description,event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE TAG</h4>
        </div>
        <div className="modal-body ">
            
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">Name:</label>
                <input type="text" placeholder="Tag Name" required onChange={(e) => setTagname(e.target.value)}  className="form-control" id="recipient-name"  />
              </div>
               <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Description:</label>
                <textarea type="text" name="type" className="form-control"  onChange={(e) => setDescription(e.target.value)}  />

              </div>
             
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Saving....' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal> 
      <Modal show={showsecondModal} contentClassName="custom-modal-content" onHide={secleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => addlist(listname, event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE LIST</h4>
        </div>
        <div className="modal-body ">
            
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">List Name:</label>
                <input type="text" placeholder="List Name" className="form-control" required onChange={(e) => setListname(e.target.value)} value={listname} id="recipient-name"  />
              </div>
             
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn"  disabled={processing}>{processing ? 'Saving....' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal>
        <section className="">
          <div className="container" style={{ marginLeft: '0px' }}>
            <div className="row ">

              <div className="col-12" style={{ marginLeft: '-2px' }}>


                <div className="crm_bd userEditForm mt-4 ">
                  <h5>&nbsp;Upload Excel </h5>
                  {/* <div class="frame">
                      <div class="center">
                        <div class="titleh">
                          <h1>Drop file to upload</h1>
                        </div>

                        <div class="dropzone">
                          <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                          <input type="file" class="upload-input" />
                        </div>

                        <button type="button" class="btnh" name="uploadbutton">Upload file</button>

                      </div>
                    </div> */}

                  <div class="
            ">
                    <div>
                      {!fileData && (
                        <div id="upload-container" style={{    width: '19%'}}>
                          <input type="file" id="upload" accept=".xlsx, .xls, .csv" onChange={handleFileChange} style={{ display: 'none' }} />
                          <label htmlFor="upload" className="upload-button">
                            <img src={upload}  alt="Upload Icon" className="upload-icon" />
                            Upload Excel
                          </label>
                        </div>
                      )}
                      {fileData && (
                        <div>
                          <p>
                            {fileData.fileName}:
                            <span class="csv_detail"> {fileData.numRows} lines with {columnNames.length} columns</span>
                            <img src={deletes} alt="Delete Icon" className="delete-icon" onClick={handleDelete} />
                          </p>
                          {!laststeps && 
                          (<div class="container mt-5" style={{ paddingRight: '37%' }}>
                            <h3 style={{ color: "#fff" }}>Data Mapping</h3>
                            <div class="alert alert-info mb-4"> It is required to have the mapping for First Name, Phone </div>
                            {columnMappings.map((mapping, index) => (
                              <div key={index} class="row mt-3">
                                <div class="col-6">
                                  <div class="source_fields">
                                    <input type="text" disabled class="form-control mappedInput" id={`columnName${index + 1}`} title="Client Name" placeholder={mapping.columnName} />
                                  </div>
                                </div>
                                <div class="col-1"><img src={ticked} style={{height: '22px'}}></img></div>
                                <div class="col-5">
                                  <div class="form-group">
                                  <select className="form-control importOption" 
                                          onChange={(event) => handleChange(event, index)}  // Pass index as an argument to handleChange
                                          id={`importOption${index + 1}`}
                                        >
                                          <option value="do_not_import">Do not import</option>
                        <option value="firstname" disabled={getDisabledOptions().includes('firstname')}>First name</option>
                        <option value="lastname" disabled={getDisabledOptions().includes('lastname')}>Last name</option>
                        <option value="phone" disabled={getDisabledOptions().includes('phone')}>Phone</option>
                        <option value="address" disabled={getDisabledOptions().includes('address')}>Property address</option>
                        <option value="city" disabled={getDisabledOptions().includes('city')}>City</option>
                        <option value="state" disabled={getDisabledOptions().includes('state')}>State</option>
                        <option value="zip" disabled={getDisabledOptions().includes('zip')}>Zip</option>
                        <option value="email" disabled={getDisabledOptions().includes('email')}>Email</option>
                    
                                          {/* <option value="do_not_import">Do not import</option>
                                          <option value="first_name">First name</option>
                                          <option value="last_name">Last name</option>
                                          <option value="phone">Phone</option>
                                          <option value="property_address">Property address</option>
                                          <option value="email">Email</option>
                                          <option value="list">Lists</option>
                                          <option value="tags">Tags</option> */}
                                          {/* Add more options here */}
                                        </select>

                                  </div>
                                </div>
                                
                              </div>
                              
                            ))}
                          </div>
                           )}
                          {laststeps && (
                          <div className={`col-6 LST-TAG mt-3 ${laststeps ? 'showh' : ''}`}>
                              <div className="row  ">
                                {/* <div className="col-3">
                                  <label htmlFor="mySelect" className="choose-list-label mt-1">Choose List:</label>
                                </div> */}
                                {/* <div className="col-5">
                                  <select value={selectedList} onChange={(e) => setSelectedList(e.target.value)} id="mySelect" className="lst_tag">
        <option > Choose List</option>
                                  
                                  {list.map((item) => (
        
        <option value={item.listname}> {item.listname}</option>
        
    ))} </select>
                                </div> */}
                                <div className="col-4">
                                <button onClick={secModalOpen} type="button" class="btn btn_primary add_btn text-left" title="">
                                  <img style={{marginRight:'14px'}} src={add} alt=""></img>ADD NEW </button>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-3">
                                  <label htmlFor="mySelect" className="choose-list-label mt-1">Choose Tag:</label>
                                </div>
                                <div className="col-5">
                                  <select  value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)} id="mySelect" className="lst_tag">
        <option > Choose Tag</option>
                                  
                                  {tags.map((item) => (
        
        <option value={item.tagname}> {item.tagname}</option>
        
    ))}
                                  </select>
                                </div>
                                <div className="col-4">
                                <button onClick={handleModalOpen} type="button" class="btn btn_primary add_btn text-left" title="">
                                  <img style={{marginRight:'14px'}} src={add} alt=""></img>ADD NEW </button>
                                </div>
                              </div> 
                          </div>
                          )}
                          <div class="container mt-5" style={{ paddingRight: '37%' }}>
                              <div  class="row mt-3">
                                <div class="col-4">
                                  <div class="">
                                  </div>
                                </div>
                                <div class="col-4">
                                 <div  class="text-center next_pre">

                                 
  
                                    <div className="text-center next_pre">
                                      <button type="button" onClick={revstep} className="btn plus_btn pre_btn">
                                        <img src={arrowright} className="flip" alt="" />
                                      </button>
                                      {!laststeps ?
                                       (<button onClick={changestep} type="button" className="btn btn_primary plus_btn next_btn" disabled="">
                                        <img src={arrowright} alt="" />
                                      </button>
                                       ) : (
                                        <button type="submit" onClick={handleUpload} className="btn btn-primary save_btn" style={{marginLeft:'20px',width:'100px'}}>Upload
                                        </button>
                                        
                                    )}
                                    </div>
                                 
                                  
                                   

                                  </div>
                                  
                              </div>
                                <div class="col-3"></div>
                                </div>
                                </div>
                              
                          
                        </div>
                      )}
                    </div>

        

                    
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        {/* Additional content for Page 1 */}
      </Sidebar>
    );
  }

}

export default Exupload;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../components/config';
import Sidebar from '../components/navbar';
import profile from '../icons/profile.svg';
import { FaUserCircle, FaCog, FaSignOutAlt } from 'react-icons/fa';
import upload from '../icons/upload.svg';
import Menu from '../components/menu';
function Settings() {
 
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [passformprocessing, setPassformProcessing] = useState(false);

  const [authenticated, setAuthenticated] = useState(null);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [id, setUserid] = useState('');
  const [success, setSuccess] = useState(false);
  const [passwords, setPasswords] = useState({ password: '', confirmPassword: '' });
  const [oldpassword, setOldpassword] = useState('');
  const [passerror, setPassError] = useState('');
  const [passsucc, setPassucc] = useState('');
  const [profilePic, setProfilePic] = useState(null);
  const [showprofilePic, setshowProfilePic] = useState(null);
  const [profileimage, setProfileimage] = useState('');
  const [chkimagename, setCheckimagename] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(file);
        setshowProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePassChange = (e) => {
    const { name, value } = e.target;
    
    setPasswords(prevPasswords => ({
      ...prevPasswords,
      [name]: value
    }));
   

  };
  useEffect(() => {
    
    const fetchUserData = async () => {
      const loggedInUser = sessionStorage.getItem("login");
    if (loggedInUser) {
      setAuthenticated(loggedInUser);
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setUserid(id)
        try {
          const {data: { _id,firstname, lastname,email,profile,timezone }} = await axios.post(`${API_BASE_URL}/api/userinfo`, {
            id
          });
          setFirstname(firstname)
          setLastname(lastname)
          setEmail(email)
          setTimeZone(timezone)
          const imageUrl =  'https://app.prophone.io/static/media/' + profile;
          // const imageUrl = process.env.PUBLIC_URL + '/profile/' + profile;
          setProfileimage(imageUrl)
          setCheckimagename(profile)
          
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
      
    };
  
    fetchUserData(); 
  }, []);
  const Updatepassword = async (e) => {
    setPassucc(false);

    const newpassword = passwords.password
    setPassformProcessing(true)
    e.preventDefault();
    if (passwords.password !== passwords.confirmPassword) {
      setPassError('Password do not match');
    setPassformProcessing(false)
      

    
  }else {
   
    setPassError('');
    
     try {
      const response = await axios.post(`${API_BASE_URL}/api/update-password`, {
        newpassword,
        oldpassword,
        
        id,
      });
      // setPassucc(true)
      setProcessing(false)
      if(response.data == "0"){
        setPassError('Old Password is Incorrect');

      }else{
        setPassError('');
        setPassucc(true);
      }
     
      
    } catch (error) {
      // setError('Login failed');
    }
    setPassformProcessing(false)

      
    }
  };
  const [timeZones, setTimeZones] = useState([]);
  const [timezone, setTimeZone] = useState("");
  const TIMEZONE_OPTIONS = [
    { name: "America/New_York", label: "Eastern Time (ET)" },
    { name: "America/Chicago", label: "Central Time (CT)" },
    { name: "America/Denver", label: "Mountain Time (MT)" },
    { name: "America/Los_Angeles", label: "Pacific Time (PT)" },
  ];
  useEffect(() => {
    const zones = TIMEZONE_OPTIONS.map(({ name, label }) => {
      const offset = new Intl.DateTimeFormat("en-US", {
        timeZone: name,
        timeZoneName: "shortOffset",
      })
        .formatToParts(new Date())
        .find((part) => part.type === "timeZoneName")?.value;

      return { name, label: `${label} (${offset})` };
    });

    setTimeZones(zones);
  }, []);
  const handleSubmit = async (e) => {
    setSuccess(false)
    const formData = new FormData();
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('id', id);
    formData.append('profile_pic', profilePic);
    formData.append('timezone', timezone);
    setProcessing(true)
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/update-user`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data == 1){
        alert("Please Choose Another Email")
        setProcessing(false)

      }else{
        setSuccess(true)
        setProcessing(false)
      e.preventDefault();
      }
  
     
      
     
      
    } catch (error) {
      
    }
  };
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
      <Sidebar>
      
  
    <div className="row ml-3">
      <div className="col-3" style={{marginRight: '-60px'}}>
      <Menu/>

      </div>
      <div className="col-9" >
        <div class="serch_bar full_width">
    <div class="tab">
        <div routerlinkactive="active" placement="bottom" show-delay="500" class="tab-item active">
            <a class="link disabled" >User Profile</a>
            <div class="strip"></div>
        </div>
    </div>
    </div>
    <div className="userEditForm">
      <form onSubmit={handleSubmit}>
        
        <div className="row">
        {success && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Profile Updated Successfuly !</div>}
          <div className="col-md-4 form-group with_210">
            <label className="mb-2 ml-2">First Name *</label>
            <input type="text" onChange={(e) => setFirstname(e.target.value)}   value={firstname}  placeholder="First name" className="form-control"  />
          </div>
          <div className="col-md-4 form-group with_210">
            <label className="mb-2 ml-2">Last Name *</label>
            <input type="text" onChange={(e) => setLastname(e.target.value)} value={lastname} placeholder="Last name"  className="form-control"  />
          </div>
          <div className="col-md-4 form-group">
            <label className="ml-3 mb-2">Email *</label>
            <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email" formControlName="email"  id="app_setting_profile-profile-email" className="form-control from-control--email"  />
          </div>
        </div>
        <div className='col-md-4 mt-2  form-group'>
                <label className="text-white">Select Time Zone:</label>
                <br />
                <select
     id="app_setting_timezone"
      name="timezone"
       className="form-control form-select custom-dorpdown-toglle"
       style={{borderColor:'#403e3e !important'}}
       required
       value={timezone }
      onChange={(e) => setTimeZone(e.target.value)}
    >
      <option value="" disabled>
        Select Time Zone
      </option>
      {TIMEZONE_OPTIONS.map(({ name, label }) => (
        <option key={name} value={name}>
          {label} 
        </option>
      ))}
    </select>
              </div>
        <br></br>
        
        <div className="row mb-3">
        <div className="col-12 form-group margRt60 col-lg-2">
          <label className="form_lbl paddLft15">Profile Photo</label>
          <div className="d-flex align-items-center">
            <span className="profile_pic">
              <br></br>
              {showprofilePic ? (
        <img 
          className="rounded-circle" 
          style={{ height: '7.5rem', width: '7.5rem' }} 
          src={showprofilePic} 
          alt="Profile Pic" 
        />
      ) : (
        chkimagename ? (
          <img 
            className="rounded-circle" 
            style={{ height: '7.5rem', width: '7.5rem' }} 
            src={profileimage} 
            alt="Default Profile Pic" 
          />
        ) : (
          <FaUserCircle size={60} />
        ))}</span>
          </div>
        </div>
        <div className="col-md-4 form-group mt-4">
          <div className="upload-container">
            <input type="file" id="upload" accept="image/*" style={{ display: 'none' }} onChange={handleFileChange} />
            <label htmlFor="upload" className="upload-button">
            <img src={upload} alt="Upload Icon" class="upload-icon"></img>
              CHANGE PICTURE
            </label>
          </div>
        </div>
      </div>
        <div className="modal_btn prifile_btn text-center mt-4 mb-2">
          
          <button type="submit" className="btn btn-primary save_btn" 
          disabled={processing}>{processing ? 'Updating....' : 'Update Profile'}</button>
        </div>
      </form>
    </div>
    <div className="userPasswordChange mt-5">
      <form  onSubmit={Updatepassword}>
        <h5>Reset Password</h5>
        {passerror && <div className="col-12 text-danger mt-2 mb-1">{passerror}</div>}
        {passsucc && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Password Updated Successfuly !</div>}
        
        <div className="row mt-4">
          <div className="col form-group ">
            <label className="mb-2">Old Password</label>
            <input
              type="password"
              name="old_password"
              placeholder="Old Password"
              
              value={oldpassword}
              onChange={(e) => setOldpassword(e.target.value)} 
              
              className="form-control"
              required
            />
          </div>
          <div className="col form-group with_210">
            <label className="mb-2">New Password</label>
            <input
              type="password"
              name="password"
              placeholder="New Password"
              value={passwords.password}
              
              onChange={handlePassChange}
              
              
              className="form-control"
              required
            />
          </div>
          <div className="col form-group with_210">
            <label className="mb-2">Confirm New Password</label>
            <input
              type="password"
              name="confirmPassword"
              placeholder="New Password"
              value={passwords.confirmPassword}
              
              onChange={handlePassChange}
              
             
              className="form-control"
              required
            />
          </div>
        </div>
        <div className="modal_btn prifile_btn text-center mt-4 mb-4">
          
          <button type="submit" className="btn btn-primary save_btn mb-4" disabled={passformprocessing}>{passformprocessing ? 'Updating Password....' : 'Update Password'}
          </button>
        </div>
      </form>
    </div>
    </div>
      
  </div>


      {/* Additional content for Page 1 */}
    </Sidebar>
    );
  }
}

export default Settings;

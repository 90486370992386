import React, { useEffect, useState,useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import { TelnyxRTC } from '@telnyx/webrtc';
import Sidebar from '../components/navbar';
import { FaMicrophone,FaMarker,FaBuilding,FaBookmark,FaReadme,FaMicrophoneSlash,FaIcons,FaList,FaChartLine,FaDollarSign,FaBullhorn,FaCalendarCheck,FaReply,FaTags,FaBan,FaCommentDots,FaPaperclip,FaPaperPlane,FaPenSquare,FaSun,FaUser, FaHistory, FaThumbsDown, FaHome, FaFire, FaThermometerHalf, FaInbox, FaPhone, FaTimes, FaNotesMedical, FaInfoCircle, FaTrash, FaEdit, FaFileContract, FaAddressBook, FaFilter, FaCog } from 'react-icons/fa';
import loadinggif from '../icons/loading.gif';
import _ from 'lodash'; // lodash for debouncing

import add from '../icons/add.svg';
import missedcall from '../icons/missed_call.png';
import calloading from '../icons/call-loading.gif';
import searchicon from '../icons/std.svg';
import sound from '../assets/sound.mp3';
import DialTone from '../assets/DialTone.mp3';
import startcalltune from '../assets/startcall.mp3';
import Hangup from '../assets/HangUp.mp3';
import { Modal, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import API_BASE_URL from '../components/config';

import '../css/phone.css'
import user1 from '../icons/user1.jpg'
import user2 from '../icons/user2.jpg'
import phoenumbers from "./numbers";
function Phone() {
  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
  const [clickHereVisible, setClickHereVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showsecondModal, setsecShowModal] = useState(false); // State to control the modal's visibility
  const [updatemessages, setnoupdatemessage] = useState(false); // State to control the modal's visibility
const [page, setPage] = useState(1);
const [showModal, setShowModals] = useState(false);
const navigate = useNavigate();
const [sipusername, setSipusername] = useState('');
const [isModalOpen, setIsModalOpen] = useState(false);
const [fetchingChats, setFetchingChats] = useState(false); 
const [nochatfound, setNochat] = useState(false); 
// const handleModalOpenofphone = () => setShowModals(true);
const handleModalOpenofphone = () => {
    // Function to open the modal
    setIsModalOpen(true);
    setShowModals(true);
  };

const handleModalOpenofphoneClose = () => {
    setShowModals(false);
    navigate('/integerations'); // Navigate to home page on modal close
  };

  const [callingModal, setCallingModal] = useState(false);
 
const handleModalOpenofcalling = () => setCallingModal(true);
const handleModalOpenofcallingClose = () => {
  if (!call){
    setStatus('')
    setCall(null)
    setCallingModal(false);
    setInputValue('')
  }
  
   
  };
  const [id, setUserid] = useState(''); 
  const [name, setNumberName] = useState(''); 
  const [addnum, setAddnumdiv] = useState('');
  const [reply,setReply] = useState('') 
  const [phonenumbers, setPurchasednumbers] = useState([]); 
  const [audience, setAudience] = useState([]); 
  const [oldaudience, setoldAudience] = useState([]); 
  
  const [phoneNumber, setPhoneNumber] = useState('');
  const [chats, setChats] = useState([]); 
  const [messages, setMessages] = useState([]); 
  const [autoreplies, setReplies] = useState([]); 
  const [message, setMessage] = useState(''); 
  const [currentstatus, setCurrentstatus] = useState(''); 
const [callstatus, setStatus] = useState('');
const chatEndRef = useRef(null);
const [loading, setLoading] = useState(false);
  const [headernumber, setheaderConditionumber] = useState(''); 
  const [selectedOption, setSelectedOption] = useState(null);
  const [contactinfo, setContactifo] = useState([]); 
  const [activeId, setActiveId] = useState(null);
  const [activechat, setActiveChat] = useState(null);
  const activechatRef = useRef(activechat);
  const [list, setList] = useState([]);
  const [tags, setTags] = useState([]);
  const [chatidz, setIdz] = useState([]);
  const [tagname, setTagname] = useState('');
  const [description, setDescription] = useState('');
  const [numbernameupdt, setNumbernamefupdate] = useState('');
  const initializeRef = useRef(false);
  const [statusFilter, setStatusFilter] = useState('All');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectionBox, setOpenselectionbox] = useState(false);
  const chatContainerRef = useRef(null);
  const dropdownRef = useRef(null);
const audioRef = useRef(null);
const DialToneRef = useRef(null);
const HangupRef = useRef(null);
const startcallref = useRef(null);
const remoteAudioRef = useRef(null);
  useEffect(() => {
    activechatRef.current = activechat;
  }, [activechat]);
  const fetchfilterChats = async (status) => {
    setSelectedItems([]);
    setIdz([]);
    setChats([])
    setFetchingChats(true)
    setLoading(true)
    try {
        const response = await fetch(`${API_BASE_URL}/api/fetch-chats`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userid:id,
              id:activeId,
              page: 1,
                  limit: 100,
                status: status // Include the status in the request body
            })
        });
       
        const data = await response.json();
        setChats(data.chats);
        
        const ids = data.chats.map(chat => chat.id); // Extract IDs
  setIdz(ids)
  

    
    } catch (error) {
        console.error('Error fetching chats:', error);
    }
    setLoading(false)
    setLoading(false)
    setFetchingChats(false)
    setLoading(false)
    setLoading(false)
    console.log("updated")
};
  const handleStatusFilter = (status) => {
    setMessage('');
  setPage(1)
  setPage(1)
    pageRef.current = 1
    setActiveChat([])

    setLoading(true)
    setSelectedOption(status)
    setActiveChat(false)

    setDropdownOpen(false);
    setAddnumdiv(false)
    setContactifo([])
    setMessages([])
    setheaderConditionumber('')
    fetchfilterChats(status)
  };
  const normalizePhoneNumber = (number) => {
    const digits = number.replace(/\D/g, ''); // Remove all non-digit characters
    const formattedNumber = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    return formattedNumber;
  };
  const [callDuration, setCallDuration] = useState(0);
  const [callInterval, setCallInterval] = useState(null);
  const [highlightNumber, setHighlightNumber] = useState(null);
  const highlightChat = (number) => {
    const num = normalizePhoneNumber(number)
    setHighlightNumber(num);
    
    setTimeout(() => {
      setHighlightNumber(null);
    }, 3000); // Highlight for 3 seconds
  };
  // useEffect(() => {
  //   if (highlightNumber) {
  //     // alert(highlightNumber);
  //   }
  // }, [highlightNumber]);
  const filteredChats = chats.filter((item) => {
    
  //   if (statusFilter === 'All') {
  //     return true;
  //   } else if (statusFilter === 'Unread') {
  //     return item.not_responded !== 0;
  //   }else if (statusFilter === 'Not Responded') {
  //       return item.not_responded !== 0;
      
  //   }else if (statusFilter === 'Read') {
  //     return item.read !== 0;
    
  // } else {
  //     return item.status === statusFilter;
  //   }
  const matchesStatus = statusFilter === 'All' || 
      (statusFilter === 'Unread' && item.not_responded !== 0) ||
      (statusFilter === 'Not Responded' && item.not_responded !== 0) ||
      (statusFilter === 'Read' && item.read !== 0) ||
      item.status === statusFilter;

    // const matchesSearchTerm = item.number.includes(searchTerm) ||
    //   (item.contact_info.firstname && item.contact_info.firstname.toLowerCase().includes(searchTerm.toLowerCase())) ||
    //   (item.contact_info.lastname && item.contact_info.lastname.toLowerCase().includes(searchTerm.toLowerCase()));

    return matchesStatus ;
  });
  const [listname, setListname] = useState('');

  const handleClearPhoneNumber = () => {
    setAddnumdiv(false)
    setContactifo([])
    setMessages([])
    setheaderConditionumber('')
    setPhoneNumber('')
    // setPhoneNumber(phoneNumber.slice(0, -1)); // Remove the last character
  };
  const handleModalOpen = () => setsecShowModal(true); // Function to open the modal
  const handleModalClose = () => setsecShowModal(false)
  
  const [showlistmodal, setlistShowModal] = useState(false); // State to control the modal's visibility

  const handlelistModalOpen = () => setlistShowModal(true); // Function to open the modal
  const handlelistModalClose = () => setlistShowModal(false)
  
  const [showtagmodal, settagShowModal] = useState(false); // State to control the modal's visibility
  const lastUpdateTimestamp = useRef(Date.now());
  const handletagModalOpen = () => settagShowModal(true); // Function to open the modal
  const handletagModalClose = () => settagShowModal(false)
  

  const [showstatusmodal, setstatusShowModal] = useState(false); // State to control the modal's visibility

  const handlestatusModalOpen = () => setstatusShowModal(true); // Function to open the modal
  const handlestatusModalClose = () => setstatusShowModal(false)
  const [prevUnreadCount, setPrevUnreadCount] = useState(0);
  

  const [shownotesmodal, setnotesShowModal] = useState(false); // State to control the modal's visibility

  const handlenotesModalOpen = () => setnotesShowModal(true); // Function to open the modal
  const handlenotesModalClose = () => setnotesShowModal(false)
  const [showeditnamemodal, seteidtnameShowModal] = useState(false); // State to control the modal's visibility
  const handleeditModalOpen = (itemName) => {
    setNumberName(itemName);
    seteidtnameShowModal(true);
  };

  const handleeditModalClose = () => seteidtnameShowModal(false)
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showreplyemodal, setreplyeShowModal] = useState(false); // State to control the modal's visibility

  const handlereplyeModalOpen = () => setreplyeShowModal(true); // Function to open the modal
  const handlereplyeModalClose = () => setreplyeShowModal(false)
  // auto reply second modal for new numbers
  const [shownewnumreplymodal, setshownewnumreplymodal] = useState(false); // State to control the modal's visibility

  const handlenewnumreplyeModalOpen = () => setshownewnumreplymodal(true); // Function to open the modal
  const handlenewnumreplyeModalClose = () => setshownewnumreplymodal(false)
  
  const [profilePic, setProfilePic] = useState(null);
  const [showProfilePic, setShowProfilePic] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(file);
        setShowProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleRemoveImage = () => {
    setProfilePic(null);
    setShowProfilePic(null);
  };

  const [authenticated, setAuthenticated] = useState(null);
  const hasMounted = useRef(false);
  // useEffect(() => {
  //   const pruchasednumbers = async () => {
  //     const id = sessionStorage.getItem("login");
  //     setLoading(true)
  //     if (id) {
  //       if (!showModal) {
  //         try {
  //           const responsej = await axios.post(`${API_BASE_URL}/api/api-check`, { id });

  //           if (responsej.data === null) {
  //             handleModalOpenofphone();
  //           }
  //         } catch (error) {
  //           console.error("Error checking API:", error);
  //         }
  //       }
  //       setAuthenticated(true)
  //       setUserid(id)
  //       try {
  //         const response= await axios.post(`${API_BASE_URL}/api/fetch-numbers`, {
  //           id
  //         });
  //         setPurchasednumbers(response.data);
  //         const firstPhoneNumber = phonenumbers[0];
  //         const firstChat = filteredChats[0];
  //         // alert(response.data[0].id)
  //         // setInitialized(false)
  //         // setActiveId(response.data[0].id);
  //         // alert(id)
  //         // handlePhoneNumberClick(response.data[0].id,id);
  //         // handlePhoneNumberClick(response.data[0].id)
  //       // handleClick(chats[0].id, chats[0].message, chats[0].contact_info,chats[0].number,chats[0].status)
  //       //   setActiveChat(chats[0].id)
  //         // if (response.data.length > 0) {
  //         //   const firstPhoneNumber = response.data[0];
  //         //   alert(firstPhoneNumber)
  //         //   setActiveId(firstPhoneNumber.id);
  //         //   handlePhoneNumberClick(firstPhoneNumber.id);
  //         //   alert(firstPhoneNumber.id); // This alert will be shown only once
  //         // }
          
  //         const replies= await axios.post(`${API_BASE_URL}/api/fetch-replies`, {
  //           id
  //         });
  //         setReplies(replies.data);

  //          const lists= await axios.post(`${API_BASE_URL}/api/fetch-lists`, {
  //           id
  //         });
  //         setList(lists.data);

  //         const tags= await axios.post(`${API_BASE_URL}/api/fetch-tag`, {
  //           id
  //         });
  //         setTags(tags.data);
  //         setLoading(false)
  //       } catch (error) {
          
  //       }
  //     } else {
  //       navigate("/login");
  //     }
  //   };
  
  //   pruchasednumbers(); 
  // }, []);
  useEffect(() => {
    if (hasMounted.current) return; // Prevent running on subsequent renders

    const fetchPurchasedNumbers = async () => {
      // console.log("fetchPurchasedNumbers called");

      const id = sessionStorage.getItem("login");
      setLoading(true);

      if (id) {
        setUserid(id);
        setAuthenticated(true);

        try {
          // console.log("Checking API for null response");
          const responsej = await axios.post(`${API_BASE_URL}/api/api-check`, { id });
          // alert(responsej.data.api)
          if (responsej.data.api === null && !isModalOpen) {
            // alert("popup opene")
            handleModalOpenofphone();
          }
          setSipusername(responsej.data.sip)
          // console.log("Fetching numbers");
          const response = await axios.post(`${API_BASE_URL}/api/fetch-numbers`, { id });
          setPurchasednumbers(response.data);
          
          // console.log("Fetching replies");
          const repliesResponse = await axios.post(`${API_BASE_URL}/api/fetch-replies`, { id });
          setReplies(repliesResponse.data);

          // console.log("Fetching lists");
          const listsResponse = await axios.post(`${API_BASE_URL}/api/fetch-lists`, { id });
          setList(listsResponse.data);

          // console.log("Fetching tags");
          const tagsResponse = await axios.post(`${API_BASE_URL}/api/fetch-tag`, { id });
          setTags(tagsResponse.data);

        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    };

    fetchPurchasedNumbers();
    hasMounted.current = true; // Set ref to true after first mount
  }, []);
  const [isHidden, setIsHidden] = useState(false);
  const [colWidth, setColWidth] = useState(4); // Default width of col-4

  const handleHide = () => {
    // setIsHidden(true);
    setColWidth(7);
    // setTimeout(() => {
    //     setColWidth(7);
    //   }, 500);

      const targetInput = document.querySelector('.send-btn');
   
      targetInput.style.width = '10%'; 
    };
        const [typingTimeout, setTypingTimeout] = useState(0);

    const handleSearchChange = async (event) => {
      setSearchTerm(event.target.value);
      if (typingTimeout) {
        clearTimeout(typingTimeout);
    }

    // Set a new timeout
    setTypingTimeout(setTimeout(() => {
        sendSearchRequest(event.target.value);  // Send request after timeout
    }, 500));  // Adjust delay as needed (e.g., 500ms)

      //
    };
    const [showButton, setShowButton] = useState(Object.keys(contactinfo).length <= 3);
    useEffect(() => {
      
      setShowButton(Object.keys(contactinfo).length <= 3);
    }, [contactinfo]);
    useEffect(() => {
      if (textareaRef.current) {
        const textarea = textareaRef.current;
        textarea.style.height = "36px"; // Reset height to initial value
        textarea.style.height = `${textarea.scrollHeight}px`; // Dynamically adjust height
      }
    }, [message]);
    const sendSearchRequest = async (value) => {
      setLoading(true)
      setFetchingChats(true)
    setActiveChat(false)
    setAddnumdiv(false)
    setContactifo([])
    setMessages([])
    setheaderConditionumber('')
    
      const response = await fetch(`${API_BASE_URL}/api/fetch-chats`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              userid: id,
              id: activeId,
              page: 1,
              limit: 100,
              search: value  // Send the search value to the backend
          })
      });

      const data = await response.json();
      // Handle the fetched data as needed
      // console.log(data);
      setChats(data.chats)
      setIdz(data.idz)
      setLoading(false)
      setFetchingChats(false)
      lastUpdateTimestamp.current = Date.now();
  };
  const clearActiveChatAsync = async () => {
  try {
    // await someAsyncOperation();
    setActiveChat(null);
  } catch (error) {
    console.error('Error clearing active chat:', error);
  }
};

//    const handlenewmessgaeclick = () => {
//   // Clear states asynchronously
//   setMessages([])
//   setContactifo([])
//   const clearActiveChatAsync = () => {
//     return new Promise((resolve) => {
//       setActiveChat(null);
//       setActiveChat(false);
//       setActiveChat('');
//       resolve();
//     });
//   };

//   // Initial state updates
//   setShowProfilePic('');
//   setProfilePic('');
//   setnoupdatemessage(true);

//   clearActiveChatAsync().then(() => {
//     // Additional state updates after clearing active chat
//     if (addnum) {
//       setAddnumdiv(false);
//       // set('');
//       // setNoupdateMessage(false); // If needed, uncomment
//     } else {
//       setAddnumdiv(true);
//     }
//     // console.log('inside', updateMessages);
//   });
// };

    // const add = "http://localhost:3000/profile/66524432b99d8606c1cef6b6.jpg"
  const handleShow = () => {
    // setIsHidden(false);
    setColWidth(4);
    const targetInput = document.querySelector('.send-btn');
   
      targetInput.style.width = '16%'; // Reset width to col-4 when shown
  };
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 5;
  // const totalPages = Math.ceil(phonenumbers.length / itemsPerPage);
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const currentItems = phonenumbers.slice(startIndex, endIndex);
  //  // Set items per page
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };
  // const renderPageNumbers = () => {
  //   const pageNumbers = [];
    
  //   for (let i = 1; i <= totalPages; i++) {
  //     pageNumbers.push(
  //       <li
  //         key={i}
  //         className={`page-item ${i === currentPage ? 'active' : ''}`}
  //         onClick={() => handlePageChange(i)}
  //       >
  //         <a className="page-link" href="#">
  //           {i}
  //         </a>
  //       </li>
  //     );
  //   }
  //   // console.log(pageNumbers)

  // }
  const { numberId  } = useParams();
  
  // useEffect(() => {
  //   if(!initializeRef.current){
  //     // alert('no')
  //     if (numberId) {
  //       setActiveId(numberId);
  //       handlePhoneNumberClick(phonenumbers[0].id)
  
  //     } else if (phonenumbers.length > 0) {
  //       setActiveId(phonenumbers[0].id);
  //       handlePhoneNumberClick(phonenumbers[0].id)
  //       // alert(chats[0].id)
  //       // handleClick(chats[0].id, chats[0].message, chats[0].contact_info,chats[0].number,chats[0].status)
  //     }
  //     initializeRef.current = true;

  //   }
    
  //   // console.log('d here',activeId)
  // }, [numberId , phonenumbers]);
  const formatPhoneNumber = (number) => {
    const normalizedNumber = number.replace(/\D/g, ''); 
    if (normalizedNumber.length <= 3) return `(${normalizedNumber}`;
    if (normalizedNumber.length <= 6) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3)}`;
    if (normalizedNumber.length <= 10) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6)}`;
    return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6, 10)}`;
  };

  const validatePhoneNumber = (number) => {
    const normalizedNumber = number.replace(/\D/g, ''); // Remove non-digit characters
    return normalizedNumber.length === 10; // Ensure it's exactly 10 digits
  };
  const handlePhoneInputChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedNumber);
  };
  const { numberid } = useParams();

  // const handleAddClick = async () => {
  //   if (!phoneNumber) {
  //     alert('Phone number is required');
  //     return;
  //   }
  //   if (!validatePhoneNumber(phoneNumber)) {
  //     alert('Invalid phone number format.');
  //     return;
  //   }
   
  //   try {
  //     const response = await axios.post('${API_BASE_URL}/api/add-newnum', { 
  //       userid:id,
  //       tophoneid: numberid,
  //       phone_number: phoneNumber 
  //     });
  //     const newchat = {
  //       id: response.data,
  //       number: phoneNumber, 
  //       messages: '', 
  //       status: 'New', 
       
          
          
  //         firstname: '',
  //         lastname: ''
  //     };
      
  //     const updatechat = chats ? [...chats, newchat] : [newchat];
  //     setChats(updatechat);
  //     setPhoneNumber('')
  //   } catch (error) {
  //     console.error('Error:', error);
  //     // Handle error (e.g., show an error message)
  //   }
  // };
  const [showimporoptionsmmodal, setImportoptShowModal] = useState(false);
  const handleimportoptModalOpen = (numname) => {
    // alert(numname)
    setNumberName(numname)
    setImportoptShowModal(true); 
};
  const handleimportoptModalClose = () => setImportoptShowModal(false);

  const[lnum,setLnum] = useState(0)
  const fetchAudeince = async (phone) => {
  const response = await axios.post(`${API_BASE_URL}/api/fetch-audience-data`, { 
        userid:id,
        number:formatPhoneNumber(phone),
       
            // ...(selectedOption !== "All" && { status: selectedOption })
      });
      setAudience(response.data)
      setoldAudience(response.data)
      
      };
  const handleClick = async (ids,messages,number,status) => {
    const response = await axios.post(`${API_BASE_URL}/api/update-chat/${ids}/${activeId}`);
    setNotes('')
    setAudience([]);
    setoldAudience([]);
    setActiveTab("CRM")
    const listsResponse = await axios.post(`${API_BASE_URL}/api/fetch-contact-single`, { id:id,number:number });
    // console.log(listsResponse.data); // Debugging ke liye check karo response kya hai

    // Agar response ek array hai, to uska pehla object extract kar lo
    if(listsResponse.data.length > 0){
      const responseData = Array.isArray(listsResponse.data) ? listsResponse.data[0] : listsResponse.data;
    // const responseDatas  = JSON.stringify(responseData)
    // alert(responseData.firstname);
    const updatedContactInfo = {
      ...responseData,
      notes: Array.isArray(responseData.notes) ? responseData.notes : [], // Default to empty array if notes isn't an array
    };
    // console.log(updatedContactInfo)
    setContactifo(updatedContactInfo);

    }else{
    setContactifo([]);

    }
    setCurrentstatus(status)
    
    // alert(contactinfo)
  //   console.log(listsResponse)
    
  //  alert(listsResponse.notes)
  fetchAudeince(number)
    setMessage('');
  setPage(1)
  if (chatContainerRef.current) {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
}
    pageRef.current = 1
    resetValues()
    // Assuming setChats is an array of chat objects and setPurchasednumbers is an array of items with an 'id' and 'read' property
    setChats([]);
setnoupdatemessage(false)
// Find the chat object with the matching id in setChats and update its unread_number to 0
const updatedChats = chats.map(chat => {
  if (chat.id === ids) {
      // Store the current unread_number to be used later
      const previousUnreadNumber = chat.unread_number;
      setLnum(previousUnreadNumber)
      return { ...chat, unread_number: 0, previousUnreadNumber };
  }
  return chat; // Return the chat object unchanged if its id doesn't match
});

// Update setChats with the updatedChats array
setChats(updatedChats);

// Update read in setPurchasednumbers for the item with matching activeId using previousUnreadNumber
// const updatedPurchasedNumbers = phonenumbers.map(item => {
//   if (item.id === activeId) {
//       // Update read property based on the previousUnreadNumber of the chat object
//       const updatedRead = item.read - lnum; // Assuming chat.previousUnreadNumber is accessible
//       return { ...item, read: updatedRead };
//   }
//   return item; // Return the item unchanged if its id doesn't match activeId
// });

// // Update setPurchasednumbers with the updatedPurchasedNumbers array
// setPurchasednumbers(updatedPurchasedNumbers);

    setClickHereVisible(false)
    // setSelectedItems([])
    // setSelectAll([])
    setPhoneNumber('')
    // const response = await axios.post(`${API_BASE_URL}/api/update-chat/${id}`);
    
    setShowProfilePic('')
   setProfilePic('');
    setAddnumdiv(false)
    // console.log('clicked id',id)
  setActiveChat(ids)
  // alert(number)
  setheaderConditionumber(number)
    setMessages(messages);
   
  
  // const updatedChats = chats.map(item => {
  //   if (item.id === id) {
  //     return {
  //       ...item,
  //       latest_inbound_message: '0', 
  //         unread_number: 0 ,
       
  //     };
  //     // return {
  //     //   ...item,
  //     //   contact_info: {
  //     //     ...item.contact_info,
  //     //     latest_inbound_message: '0', 
  //     //     unread_number: 0 
  //     //   }
  //     // };
  //   }
  //   return item;
  // });
  // setChats(updatedChats);
 

};
const [fromnumber, setPurchasednumbercalling] = useState('');
const [numbername, setPurchasednumbername] = useState('');
// adirm

const [audemail, setaudemail] = useState('');
const [audstatus, setaudstatus] = useState('');
const [audnotes, setaudnotes] = useState('');
const [notes, setNotes] = useState('');
const [audlist, setaudlist] = useState('');
const [audtag, setaudtag] = useState('');

const [call, setCall] = useState(null);

const [soundPlayed, setSoundPlayed] = useState(false);
const [showcontacteditmodal, setcontactshoweditmodal] = useState(false);
const handlecontacteditModalOpen = (contact) => {
  
  setcontactshoweditmodal(true);
};
const handlecontacteditModalClose = () => {
  setcontactshoweditmodal(false)
}

const [showcontactimportmodal, setcontactshowimportmodal] = useState(false);
const handlecontactimportModalOpen = (contact) => {
  
  setcontactshowimportmodal(true);
};
const handlecontactimportModalClose = () => {
  setcontactshowimportmodal(false)
  setAudience(oldaudience)
}
const [isDataRendered, setIsDataRendered] = useState(false);

useEffect(() => {
  if (filteredChats.length > 0 || statusFilter === 'All') {
    setIsDataRendered(true);
  }
}, [filteredChats, statusFilter]);

const [loadingState, setLoadingState] = useState({});

const handlePhoneNumberClick = async (event, phoneNumberId, id, numbercalling, name) => {
  setLoadingState((prevState) => ({
    ...prevState,
    [phoneNumberId]: true, // Set the loading state of this particular number to true
  }));
  // setLoading(true);
  setIsDataRendered(false);
  console.log("tap");
  setPurchasednumbercalling(numbercalling);
  setPurchasednumbername(name);
  setFetchingChats(true);
  setSearchTerm('');
  setSelectedOption('All');
  event.preventDefault();
  setPage(1);
  setChats([]);
  setHasMore(true);
  setheaderConditionumber('');
  setPhoneNumber('');
  setSelectedItems([]);
  setSelectAll([]);
  setActiveChat('');
  setMessages('');
  setContactifo('');
  setMessage('');
  setShowProfilePic('');
  setProfilePic('');
  setAddnumdiv(false);
  setheaderConditionumber('');
  setActiveId(phoneNumberId);

  try {
      const response = await axios.post(`${API_BASE_URL}/api/fetch-chats`, {
          userid: id,
          id: phoneNumberId,
          page: 1,
          limit: 100,
      });

      const data = response.data.chats;
      setIdz(response.data.idz);

      const updatedChats = data.map(item => ({
          id: item.id,
          number: item.number,
          messages: item.messages,
          status: item.status,
          date: item.date,
          unread_number: item.unread_number,
          latest_inbound_message: item.latest_inbound_message,
          not_responded: item.not_responded,
          read: item.read,
          contact_info: item.contact_info,
          firstname: item.firstname || '',
          lastname: item.lastname || '',
          notification: item.notification || '',
          laststatus: item.laststatus || '',
      }));
      setLoadingState((prevState) => ({
        ...prevState,
        [phoneNumberId]: false, // Set the loading state of this particular number to false after operation
      }));
      setChats(updatedChats);
      setIsDataRendered(true); // Signal that data is ready to render
    // setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  } catch (error) {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
    setIsDataRendered(false);
      console.error('Error:', error);

  } finally {
      setFetchingChats(false); // Keep fetching state separate from UI loading
  }
};  const isGlobalLoading = Object.values(loadingState).includes(true);

  // const handlePhoneNumberClick = async (event,phoneNumberId,id,numbercalling,name) => {
  //   setLoading(true)
    
  //   console.log("tap")
  //  setLoading(true)
  //  setLoading(true)
  //   setPurchasednumbercalling(numbercalling)
  //   setPurchasednumbername(name)
  //   setFetchingChats(true)
  //   setSearchTerm('')
  // setSelectedOption('All')
  //   setLoading(true)
  //   event.preventDefault()
  //   setPage(1);
  //       setChats([]);
  //       setHasMore(true);
  //   setheaderConditionumber('')
  //   setPhoneNumber('')
  // setChats([])
  // setSelectedItems([])
  //   setSelectAll([])
  //   setActiveChat('');
  //   setMessages('');
  //   setContactifo('');
  //   setPhoneNumber('');
  //   setMessage('');
  //  setShowProfilePic('')
  //  setProfilePic('');
  //       setAddnumdiv(false);
  //   setheaderConditionumber('')
  //   setLoading(true)

  // setActiveId(phoneNumberId)
  //   try {
  //     setLoading(true)
  //     const response = await axios.post(`${API_BASE_URL}/api/fetch-chats`, { 
  //       userid:id,
  //       id:phoneNumberId,
  //       page: 1,
  //           limit: 100,
  //           // ...(selectedOption !== "All" && { status: selectedOption })
  //     });
  //  setLoading(true)

  //      const data = response.data.chats;
  //      setIdz(response.data.idz);
  //     const updatedChats = data.map(item => ({
  //           id: item.id,
  //           number: item.number,
  //           messages: item.messages,
  //           status: item.status,
           
  //           date: item.date,
  //           unread_number: item.unread_number,
  //           latest_inbound_message: item.latest_inbound_message,
  //           date: item.date,
  //           not_responded:item.not_responded,
  //           read:item.read,
  //           contact_info:item.contact_info,
            
  //           firstname: item.firstname,
  //           lastname: item.lastname,
  //           notification: item.notification || '',
  //           laststatus: item.laststatus || '',
  //       }));
  //       // const audio = new Audio('/sound.mp3');
  //       // audio.play();
  //     //   const currentUnreadCount = updatedChats.reduce((acc, chat) => acc + chat.read, 0);
      
  //     // // Check if there are new unread messages
  //     // // if (currentUnreadCount > prevUnreadCount) {
  //     // //   const audio = new Audio('/sound.mp3');
  //     // //   audio.play();
  //     // // }
  //     // // setPrevUnreadCount(currentUnreadCount);
  //     //   const hasUnreadMessages = updatedChats.some(item => item.read > 0);
  //     //   console.log(hasUnreadMessages)
  //     //   if (hasUnreadMessages ) {
  //     //     const audio = new Audio('/sound.mp3');
  //     //     audio.play();
  //     //     console.log('run')

  //     //   }
   
  //   //   const sortedChats = updatedChats.sort((a, b) => {
  //   //     if (b.unread_number > 0 && a.unread_number === 0) return 1;
  //   //     if (a.unread_number > 0 && b.unread_number === 0) return -1;
  //   //     return 0;
  //   // });
    
  //   setLoading(true)

  //   setChats(updatedChats);
  //       // const uniqueChats = Array.from(new Set(chats.map(chat => chat.number))).map(number => chats.find(chat => chat.number === number));
  //       // setChats(uniqueChats)
  //       // console.log(chats)
  //       // handleClick(updatedChats[0].id, updatedChats[0].messages, updatedChats[0].contact_info,updatedChats[0].number,updatedChats[0].status)
  //       // setActiveChat(updatedChats[0].id)
  //       // fetchChatsWithDelay(phoneNumberId);
  //       // alert('reresh')
  //   setLoading(false)
  //   setFetchingChats(false)
  //   } catch (error) {
  //     console.error('Error:', error);
  //   setLoading(false)
  //   setFetchingChats(false)
  //   lastUpdateTimestamp.current = Date.now();
  //   }

  // };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false); // Close the dropdown if clicked outside of it
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [activeTab, setActiveTab] = useState('CRM'); // Default active tab

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const appendMessage = async (message,event,image) => {
    if (image){
      setProfilePic(image)

      setShowProfilePic(image)
    }
    event.preventDefault();
    setMessage(message)
    setreplyeShowModal(false)
    setshownewnumreplymodal(false)

    
  };
  const [notifications, setNotifications] = useState([]);

  const sendmessage = async (message,event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('userid', id);
    formData.append('id', activeId);
    formData.append('tophoneidsend', activechat);
    formData.append('message', message);
    formData.append('msg-status', currentstatus);
    if (!message && !profilePic ){
      alert("Message Cannot be Empty")
      return;
    
    }
    // else if (currentstatus == "DNC"){
    //   alert("Failed to Send Message")
      
    //   return;
    // }
    if (profilePic) {
      formData.append('image', profilePic);
    }
    event.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/api/send-message`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      // const response = await axios.post('${API_BASE_URL}/api/send-message', { 
      //   id:activechat,
      //   message:message,
       
       
      // });
      // if(response.data.status){
      //   setCurrentstatus()
      // }
      const newmessgae = {
     
        direction: "outbound", 
        message: message, 
        status: "Queued", 
        media: response.data, 
        createdAt: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + ' ' + (new Date().toLocaleDateString() === new Date().toLocaleDateString() ? 'Today' : new Date().toLocaleDateString())
  
      };
      
      const updatedmessage = messages ? [...messages, newmessgae] : [newmessgae];
      setMessages(updatedmessage);
      
    setMessage('');
    setreplyeShowModal(false)
    setProfilePic('')
    setShowProfilePic('')
    } catch (error) {
      
    }
  };

  const {numberIds  } = useParams();
 const updatename = async (event) => {
  event.preventDefault()
  try {
    const response = await axios.post(`${API_BASE_URL}/api/update-numbername`, { 
      id: activeId,
      name:name,
    });
    window.location.reload()
    // navigate('/numbers')
  }catch (error){

  }
 };
  // const [prevNotifications, setPrevNotifications] = useState([]);


 const setMessagesIfActiveChatMatches = async (updatedChats,activeChat) => {
 
  const activeChatItem = updatedChats.find(item => item.id === activechatRef.current);
  // console.log(addnum)
  if (activeChatItem && !loading && !fetchingChats) {
    setCurrentstatus(activeChatItem.status)
    setMessages(activeChatItem.messages);
    if (showcontacteditmodal){
      // alert("if run")
    }else{
      setContactifo(activeChatItem.contact_info);

      // alert("else run")
    }
    
    try {
              const response = await axios.post(`${API_BASE_URL}/api/update-chat/${activeChat}/${activeId}`);
              // Handle the response if needed
              // console.log(response.data);
          } catch (error) {
              // console.error('Error updating chat:', error);
          }
    }
    
  // console.log(updatedChats)
  // for (const item of updatedChats) {
  // console.log('activechat',activechat)

  //     if (item.id === activechat) {
  //       console.log(item.id,activechat,item.messages)
  //         // setMessages(item.messages);
  //         try {
  //             const response = await axios.post(`${API_BASE_URL}/api/update-chat/${activechat}`);
  //             // Handle the response if needed
  //             // console.log(response.data);
  //         } catch (error) {
  //             console.error('Error updating chat:', error);
  //         }
  //         break; // Exit the loop once the active chat is found and updated
  //     }
  // }
};
const lastNotificationTimes = [];

const playnotfic = async (updatedChats) =>{
  // console.log("run")
  updatedChats.forEach(chat => {
  // console.log(chat.notification)

    if (chat.notification && chat.notification  <= 8) {
      audioRef.current.play()

      // const audio = new Audio(sound);
      // const audios = new Audio(`/assets/sound.mp3`)
  
      // audios.play();
      // audio.play();
    //   const notificationTime = new Date(chat.notification).getTime();
    //   // Get the current time in UTC
    //   const currentTime = new Date().getTime();
    //   const timeDifferenceInMilliseconds = currentTime - notificationTime;
    //   const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
    //   // Log the original date strings and their UTC equivalents
    //   console.log('Original Notification Time:', chat.notification);
    //   console.log('timeDifferenceInSeconds:', timeDifferenceInSeconds);
    //   // console.log('Notification Time (UTC):', new Date(notificationTime).toISOString());
    //   // console.log('Current Time (UTC):', new Date(currentTime).toISOString());
  
    //   const pacificOffsetInSeconds = 8 * 60 * 60; // 8 hours * 60 minutes * 60 seconds
    
    // // Adjusted time difference in seconds
    // const adjustedTimeDifferenceInSeconds = timeDifferenceInSeconds + pacificOffsetInSeconds;
    
    // // console.log('Time Difference in Milliseconds:', timeDifferenceInMilliseconds);
    // console.log('Time Difference in Seconds (adjusted for PST):', adjustedTimeDifferenceInSeconds);
    
    // // Further logic based on adjusted time difference if needed
    // if (adjustedTimeDifferenceInSeconds <= 20) {
    //     // Perform your alert or action here
    //     audioRef.current.play()

    //     const audio = new Audio(sound);
    //     const audios = new Audio(`/assets/sound.mp3`)
    
    //     audios.play();
    //     audio.play();
    //   }
    }
  });
  // if (newNotifications.length > 0) {
    
  //   // Update the lastNotificationTimes array with the new notification datetimes
  //   lastNotificationTimes.push(...newNotifications);

  //   // console.log("New Unseen Notifications:", newNotifications);
  //   // console.log("Updated lastNotificationTimes:", lastNotificationTimes);
  // }

}
const [inputValue, setInputValue] = useState('');
const handleInputPhoneChange = (e) => {
  let value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
    if (value.length <= 10) {
      setInputValue(value);
    }
};
const handleDigitPress = (digit) => {
  
  const newValue = inputValue + digit;
  // alert(newValue)
  if (inputValue.length < 10){
    const formattedValue = formatPhoneNumber(newValue);
    setInputValue(newValue);
  }
  
};
const previousReadTotalRef = useRef(0);
const isFirstRun = useRef(true);
  const isRequestInProgress = useRef(false); // Track the status of the current request

useEffect(() => {

  const fetchNumbers = async () => {
    let id = sessionStorage.getItem("login"); // Use let for id since it will change
    try {
      isRequestInProgress.current = true;
      const response = await axios.post(`${API_BASE_URL}/api/fetch-numbers`, { id });
      setPurchasednumbers(response.data);
      if (!activeId) {
        const totalRead = response.data.reduce((sum, number) => sum + number.read, 0);

        if (!isFirstRun.current && totalRead > previousReadTotalRef.current) {
          audioRef.current.play();
        }
        isFirstRun.current = false;
        previousReadTotalRef.current = totalRead;
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error (e.g., show an error message)
    } finally {
      isRequestInProgress.current = false; // Mark request as complete
    }
  };

  const intervalId = setInterval(() => {
    if (!isRequestInProgress.current) {
      fetchNumbers();
    }
  }, 5000);

  // Clear the interval when the component unmounts
  return () => clearInterval(intervalId);
}, []);

const chatsRef = useRef(chats);
const activeIdRef = useRef(activeId);
useEffect(() => {
    activeIdRef.current = activeId;
    setFetchingChats(false); // Reset fetching state on activeId change
}, [activeId]);

// useEffect(() => {
//   setLoading(true);0
//   if(chats.length > 0){
//     // console.log("loading")
//     setLoading(false);
//   }
//   console.log(chats.length)
//   // Reset fetching state on activeId change
// }, [fetchingChats,chats]);
useEffect(() => {
    chatsRef.current = chats;
   
}, [chats]);
const [client, setClient] = useState(null);
// useEffect(() => {
//   let intervalId;

//   if (callstatus.length > 1 && callstatus !== "Active") {
//     // Set up an interval to play the dial tone repeatedly
//     intervalId = setInterval(() => {
//       DialToneRef.current.play();
//     }, 3000); // Play every 3 seconds, adjust timing as needed
//   } 
//     else if (callstatus === "destroy"){
//       HangupRef.current.play()
//       setStatus('')
//       setCall(null)
//       setCallingModal(false)
//       setInputValue('')
//     clearInterval(intervalId);

//     }
//   }, [callstatus]);
useEffect(() => {
  let intervalId;

  if (callstatus.length > 1 && callstatus !== "Active") {
    if (DialToneRef.current) {
      // Set up an interval to play the dial tone repeatedly
      intervalId = setInterval(() => {
        DialToneRef.current.play().catch(error => console.error('Error playing dial tone:', error));
      }, 3000); // Play every 3 seconds, adjust timing as needed
    }
  }

  if (callstatus === "destroy") {
    DialToneRef.current.pause()
    setCallDuration(0)
    if (HangupRef.current) {
      HangupRef.current.play().catch(error => console.error('Error playing hangup tone:', error));
    }
    setStatus('');
    setCall(null);
    setCallingModal(false);
    setInputValue('');
    clearInterval(intervalId);
    setCallDuration(0)
  }

  // Cleanup on unmount or when callstatus changes
  return () => {
    clearInterval(intervalId);
  };
}, [callstatus]);
useEffect(() => {
  if (!sipusername) {
    return; 
  }
  const client = new TelnyxRTC({
      login: sipusername,
      password: "prophone1sip",
      realm: 'sip.telnyx.com'
  });
 
  // client.on('telnyx.ready', () => {
  //     console.log('Telnyx WebRTC client ready');
  // });

  // client.on('telnyx.error', (error) => {
  //     console.error('Telnyx WebRTC error:', error);
  // });

  client.on('telnyx.notification', (notification) => {
      console.log(notification);
      if (notification.type === 'callUpdate') {
        const callState = notification.call.state;
        if (callState === 'trying' || callState === 'calling' || callState === 'active') {
          setStatus(callState.charAt(0).toUpperCase() + callState.slice(1));
        }
      }
      if (notification.type === 'callUpdate' && notification.call.state === 'ringing') {
        setCallDuration(0)
        // setStatus(notification.call.state)
      }

      if (notification.type === 'callUpdate' && notification.call.state === 'destroy') {
        setStatus(notification.call.state)
        DialToneRef.current.pause()
        setCallDuration(0)
        HangupRef.current.play()
        handleModalOpenofcallingClose()
        clearInterval(callInterval);
        setCallDuration(0)
        setCall(null)
        setInputValue('')
      }
       if (notification.type === 'callUpdate' && notification.call.state === 'active') {
        DialToneRef.current.pause()
        
        const remoteStream = notification.call.remoteStream;
        if (remoteStream && remoteAudioRef.current) {
          remoteAudioRef.current.srcObject = remoteStream;
          remoteAudioRef.current.play().catch(error => {
            console.error('Error playing remote audio stream:', error);
          });
        }
        if (callInterval) {
          clearInterval(callInterval);
        }
        setCallDuration(0);
        const interval = setInterval(() => {
          setCallDuration((prevDuration) => prevDuration + 1);
        }, 1000);
        setCallInterval(interval);
      }
  });

  client.connect();
  setClient(client);
  
  return () => {
      // client.disconnect();
      clearInterval(callInterval);
  };
}, [sipusername]);
const handleDecline = () => {
  DialToneRef.current.pause()

  HangupRef.current.play()
  
  if (call) {
      call.hangup();

  }
  setStatus('')
  setInputValue('')
  setCallingModal(false);
  setCall(null)
  clearInterval(callInterval);
        setCallDuration(0)
};
const formatPhone = (value) => {
  // Remove all non-numeric characters
  const cleaned = ('' + value).replace(/\D/g, '');
  
  // Add the country code (assumed to be +1 for this example)
  const countryCode = '+1';
  
  // Return the formatted phone number
  return `${countryCode}${cleaned}`;
};
const formatDuration = (duration) => {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
const [destinationNumber, setDestinationNumber] = useState('+16153588771');
const desinatnum = formatPhone(headernumber)

const startcall = async () => {

  if(inputValue.length !== 10){
    alert("Please Enter Correct Number")
  }else{
    const correctnumber = "+1" + inputValue
    const response = await axios.post(`${API_BASE_URL}/api/validate-number`, {
      number:correctnumber
  });
  if (response.data == 1){
    alert("Number is in DNC Manager")
  }else{
    const fromnumbers = formatPhone(fromnumber)
    if (client) {
      const newCall = client.newCall({
        destinationNumber:correctnumber,
          callerNumber: fromnumbers, // Replace with the actual caller number
          audio: true,
      });
      console.log("call",newCall)
      setCall(newCall);
      startcallref.current.play()
      
  }
  }
    
  }

  }
  const convertToLocalTime = (utcDateTime) => {
    // Remove the timezone (PKT, UTC, etc.)
    const cleanDateTime = utcDateTime.replace(/ [A-Z]+$/, ''); // Remove last word (timezone)
    
    // Convert to Date object
    const date = new Date(cleanDateTime);
    const now = new Date();

    // Check if the date is today
    const isToday = date.toDateString() === now.toDateString();

    // Format the date (Feb 12)
    const formattedDate = date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'short' // 'Feb' instead of 'February'
    });

    // Format the time (3:31 AM)
    const formattedTime = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });

    return isToday
        ? `${formattedTime} Today`
        : `${formattedDate} ${formattedTime}`;
};

// Example Usage
// console.log(convertToLocalTime("2025-02-12 03:31 AM PKT")); 
// Output: "Feb 12 3:31 AM Today" (if today is Feb 12)
// OR "Feb 12 3:31 AM" (if not today)

  
const handleStartCall = () => {
  if(currentstatus === "DNC"){
    alert("Number is in DNC Manager")
  }else{

  handleModalOpenofcalling()
  // alert(formatPhone(headernumber))
  const fromnumbers = formatPhone(fromnumber)
  
  // alert(desinatnum)
  // alert(destinationNumber)
  if (client) {
      const newCall = client.newCall({
        destinationNumber:desinatnum,
          callerNumber: fromnumbers, // Replace with the actual caller number
          audio: true,
      });
      setCall(newCall);
      startcallref.current.play()
  }
}

};
useEffect(() => {
  if (!activeId) return;

  const intervalId = setInterval(async () => {
      const timeSinceLastUpdate = Date.now() - lastUpdateTimestamp.current;
      if (timeSinceLastUpdate > 5000 && !fetchingChats) {
          try {
              setFetchingChats(true);
              const currentActiveId = activeIdRef.current;
              const response = await axios.post(`${API_BASE_URL}/api/new-chats`, {
                  id: currentActiveId,
                  userid: id,
                  live: 'live',
                  chatids: chatsRef.current.map(chat => chat.id)
              });

              if (activeIdRef.current !== currentActiveId) {
                setFetchingChats(false);
                return;
            }

            // const numberdata = response.data.unread_counts;
            // const updatedNumbers = numberdata.map(item => ({
            //     id: item._id,
            //     number: item.number,
            //     name: item.name,
            //     read: item.unread || 0,
            //     notification: item.notification || ''
            // }));

            const data = response.data.chats;
            const updatedChats = chatsRef.current.map(chat => {
                const matchingItem = data.find(item => item.id === chat.id);
                if (matchingItem) {
                    return {
                        ...chat,
                        number: matchingItem.number,
                        messages: matchingItem.messages,
                        contact_info: matchingItem.contact_info || [],
                        status: matchingItem.status || '',
                        unread_number: matchingItem.unread_number || 0,
                        latest_inbound_message: matchingItem.latest_inbound_message,
                        not_responded: matchingItem.not_responded || 0,
                        read: matchingItem.read || 0,
                        date: matchingItem.date || '',
                        firstname: matchingItem.contact_info?.firstname || '',
                        lastname: matchingItem.contact_info?.lastname || '',
                        notification: matchingItem.notification || '',
                        laststatus: matchingItem.laststatus || ''
                    };
                }
                return chat;
            });

            setChats(updatedChats);
            playnotfic(updatedChats);
            // setPurchasednumbers(updatedNumbers);

            if (activechatRef.current) {
                setMessagesIfActiveChatMatches(updatedChats, activechatRef.current);
            }

            lastUpdateTimestamp.current = Date.now();
            setFetchingChats(false);
        } catch (error) {
            console.error('Error:', error);
            setFetchingChats(false);
        }
    }
}, 7000);

  return () => clearInterval(intervalId);
}, [chats]);


// useEffect(() => {
//   if (loading) {
//     console.log('Loading started...');
//   } else {
//     console.log('Loading ended...');
//   }
// }, [loading]); // Runs whenever `loading` changes

const handlenewmessgaeclick = () => {
  setShowProfilePic('');
  setProfilePic('');
  // set(true);
 setMessages([])
  setContactifo([])
  clearActiveChatAsync().then(() => {
    setActiveChat(null);
    // setActiveChat(false);
    // setActiveChat('');
    if (addnum) {
      setAddnumdiv(false);
      setheaderConditionumber('');
    } else {
      setAddnumdiv(true);
    }
    console.log('Active chat cleared.');
  });
};
const [isClicked, setIsClicked] = useState(false);
 const [isMicMuted, setIsMicMuted] = useState(false);
    const handleMicClick = () => {
    setIsMicMuted(!isMicMuted);
    if (client) {
      const activeCall = client.activeCall;
      
      // alert(call.activeCall)
      if (call) {
        if (isMicMuted) {
          call.unmuteAudio();

        } else {
          call.muteAudio();

        }
      }
    }
  };
  const {numberkeid  } = useParams();
  const newnumbernmessage = async (message,event) => {
    event.preventDefault();
    if (!message && !profilePic ){
      alert("Message Cannot be Empty")
      return;
    }
      // Remove non-digit characters
      let digits = phoneNumber.replace(/\D/g, '');
    
      // Extract the last 10 digits (in case there are more than 10)
      let simpleNumber = digits.slice(-10);
    // setProcessing(true)
    
    event.preventDefault();

    if(simpleNumber.length == 10  ){
      const formData = new FormData();
    formData.append('userid', id);
    formData.append('phone', simpleNumber);
    formData.append('message', message);
    formData.append('tophoneid', activeId);
    if (profilePic) {
      formData.append('image', profilePic);
    }
   
        
    try {
      const response = await axios.post(`${API_BASE_URL}/api/send-message`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if(response.data == 2){
        // phone
        alert("Chat Already Exist")
        // alert(phoneNumber)
        highlightChat(phoneNumber);
        return;
      }if (response.data.status){
        setCurrentstatus(response.data.status)
      }
      // const response = await axios.post('${API_BASE_URL}/api/send-message', { 
      // userid:id,  
      // phone:simpleNumber,
      //   message:message,
      //   tophoneid:activeId
       
      // });
      const updatedChats = {
        id:response.data.id,
        number: phoneNumber,
        status: response.data.status,
        unread_number: 0,
        latest_inbound_message: message,
        contact_info :{},
        messages:response.data.messages,
        firstname:  '',
        lastname:  '',
        date: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + ' ' + (new Date().toLocaleDateString() === new Date().toLocaleDateString() ? 'Today' : new Date().toLocaleDateString())
  
      };
      handleClick(response.data.id,response.data.messages,{}, phoneNumber)
      
      const updatedchat = chats ? [updatedChats, ...chats] : [updatedChats];
setChats(updatedchat)
      const newmessgae = {
     
        direction: "outbound", 
        message: message, 
        media: response.data, 
        createdAt: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + ' ' + (new Date().toLocaleDateString() === new Date().toLocaleDateString() ? 'Today' : new Date().toLocaleDateString())
  
      };
      
      const updatedmessage = messages ? [...messages, newmessgae] : [newmessgae];
      setMessages(updatedmessage);
    setMessage('');
    setPhoneNumber('')
    setshownewnumreplymodal(false)
    setProfilePic('')
    setShowProfilePic('')
    setCurrentstatus(response.data.status)
    // alert(currentstatus)
    } catch (error) {
    }
    
    }else{
      alert('Please Enter the Correct Number')
    }
    

    
  };
  const resetValues = () => {
    setModalValues({
      status: '',
      address: '',
      notes: ''
    });
  
    setInputValues({
      firstname: '',
      lastname: '',
      email: '',
      tags: '',
      list: ''
    });
  };
  
  const [inputValues, setInputValues] = useState({
    firstname: contactinfo?.firstname || '',
    lastname: contactinfo?.lastname || '',
    email: contactinfo?.email || '',
    tags: contactinfo?.tags || '',
    list: contactinfo?.list || '',
    address: contactinfo?.address || '',
    notes: contactinfo?.notes || '',
  });
  
  const [hasMore, setHasMore] = useState(true);
  
const textareaRef = useRef(null);

  const handleInput = (e) => {
    const textarea = textareaRef.current;
    // textarea.style.height = "36px"; // Reset height to the initial value
    // textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height dynamically
    if (textarea.value.trim() === "") {
      textarea.style.height = "36px"; // Reset to initial height
    } else {
      // Adjust height based on content
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
    setMessage(e.target.value);
  };
  const handleInputChange = (e) => {
    setShowButton(true);
    const { name, value } = e.target;
    
    setInputValues(prevState => ({
      ...prevState,
      [name]: value
    }));

    // const anyFieldEmpty = Object.values(inputValues).some(value => value === '');
    // setClickHereVisible(anyFieldEmpty);
  };
  const scrollRef = useRef(null);
  const pageRef = useRef(1); // useRef to keep track of the current page number

  const fetchChats = async (phoneid,page) => {
    setFetchingChats(true);
    setLoading(true);
    try {
        const response = await axios.post(`${API_BASE_URL}/api/fetch-chats`, {
          userid:id,
          id:activeId,
          // id:phoneid,
          // id:"664148a5d496a084c23d1c50",

            page: page,
            limit: 100,
            ...(selectedOption !== "All" && { status: selectedOption })
        });
        const data = response.data.chats;
        setIdz(response.data.idz);
        if (data.length < 1) {
            setHasMore(false);
        }
        const newChats = data.map(item => ({
          id: item.id,
          number: item.number,
          messages: item.messages,
          status: item.status,
          date: item.date,
          unread_number: item.unread_number,
          latest_inbound_message: item.latest_inbound_message,
          not_responded: item.not_responded,
          read: item.read,
          contact_info: item.contact_info,
          firstname: item.firstname,
          lastname: item.lastname,
          notification: item.notification || '',
          laststatus: item.laststatus || '',
      }));
      setChats(prevChats => {
        const allChats = [...prevChats, ...newChats];
        
        const uniqueChatsMap = new Map();
        allChats.forEach(chat => {
            uniqueChatsMap.set(chat.id, chat);
        });
        
        const uniqueChats = Array.from(uniqueChatsMap.values());

        // Optionally, you can sort chats here if needed
        // uniqueChats.sort((a, b) => {
        //     if (b.unread_number > 0 && a.unread_number === 0) return 1;
        //     if (a.unread_number > 0 && b.unread_number === 0) return -1;
        //     return 0;
        // });

        return uniqueChats;
    });
      // setChats(prevChats => [...prevChats, ...newChats]);
    //   setChats(prevChats => {
    //     // Merge previous chats with new chats
    //     const allChats = [...prevChats, ...newChats];
    
    //     // Remove duplicate chats by ID
    //     const uniqueChats = allChats.filter((chat, index, self) =>
    //         index === self.findIndex(c => c.id === chat.id)
    //     );
    
    //   //   uniqueChats.sort((a, b) => {
    //   //     if (b.unread_number > 0 && a.unread_number === 0) return 1;
    //   //     if (a.unread_number > 0 && b.unread_number === 0) return -1;
    //   //     return 0;
    //   // });
  
    //   return uniqueChats;
    // });
    
      //   setChats(prevChats => {
      //     const allChats = [...prevChats, ...newChats];
      //     const uniqueChats = allChats.filter((chat, index, self) =>
      //         index === self.findIndex(c => c.id === chat.id)
      //     );
         
      //     return uniqueChats;
      // });
      //  alert(chats.length,data.length)
    setLoading(false);
    setFetchingChats(false);
    lastUpdateTimestamp.current = Date.now();
    } catch (error) {
      setLoading(false);

    } finally {
        setLoading(false);
        setFetchingChats(false)
    }
};
const ft = useRef(false);
//   const handleScroll = () => {
//   if (scrollRef.current) {
//     const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
//     if (scrollTop + clientHeight >= scrollHeight && !loading && !ft.current) {
//       ft.current = true; // Mark as fetching
//       fetchChats(activeId, pageRef.current + 1)
//         .then(() => {
//           pageRef.current += 1;
//           ft.current = false; // Reset fetching status
//         })
//         .catch(() => {
//           ft.current = false; // Reset fetching status on error
//         });
//     }
//   }
// };
const handleScroll = () => {
  if (scrollRef.current) {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    const tolerance = 10; // Adjust this value if needed
    if (scrollTop + clientHeight >= scrollHeight - tolerance && !loading && !ft.current) {
      ft.current = true; // Mark as fetching
      setLoading(true); // Set loading state
      fetchChats(activeId,  page + 1)
        .then(() => {
          pageRef.current += 1;
          setPage(prevPage => prevPage + 1);
          setLoading(false); // Reset loading state
          ft.current = false; // Reset fetching status
        })
        .catch(() => {
          setLoading(false); // Reset loading state on error
          ft.current = false; // Reset fetching status on error
        });
    }
  }
};
  // const handleScroll = () => {
  //   // console.log('scroll run')
  //   if (scrollRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
  //     // console.log(scrollTop, scrollHeight, clientHeight)
  //     if (scrollTop + clientHeight >= scrollHeight  && !loading) {
  //     console.log('runs')
  //       if(!fetchingChats){
  //       fetchChats(activeId, pageRef.current + 1);
  //       pageRef.current += 1;
  //       }
        
  //     }
  //   }
  // };
  const debouncedHandleScroll = _.debounce(handleScroll, 200); // Debounce the scroll handler

  useEffect(() => {
    const current = scrollRef.current;
    if (current) {
      current.addEventListener('scroll', debouncedHandleScroll);
    }
    return () => {
      if (current) {
        current.removeEventListener('scroll', debouncedHandleScroll);
      }
    };
  }, [debouncedHandleScroll, loading]); 
  // useEffect(() => {
  //   const current = scrollRef.current;
  //   if (current) {
  //     current.addEventListener('scroll', handleScroll);
  //   }
  //   return () => {
  //     if (current) {
  //       current.removeEventListener('scroll', handleScroll);
  //     }
  //   };
  // }, [loading]);
  const requiredFields = ['status', 'firstname', 'lastname', 'list', 'tags', 'address', 'notes', 'email', 'phone'];

  // Determine if only the status field is present
  const hasOnlyStatusField = Object.keys(contactinfo).every(
    key => requiredFields.includes(key) && (key === 'status' || !contactinfo[key])
  );
   useEffect(() => {
    const resetAndFetchChats = async () => {
      setLoading(true);
      setChats([]); // Clear previous chats
      setPage(1); // Reset to first page
      ft.current = false; // Reset fetching status
     
    };
    resetAndFetchChats();
    setLoading(false);

  }, [activeId]);
// useEffect(() => {
//   fetchChats(page);
// }, [page]);
  const handleHideClickHere = () => {
    setInputValues({
      firstname: '',
      lastname: '',
      email: '',
      tags: ''
    });
     
  };

  useEffect(() => {
 
    const anyFieldFilled = Object.values(inputValues).some(value => (value || '').trim() !== '');
    setClickHereVisible(anyFieldFilled);
  }, [inputValues]);
  
  // useEffect(() => {
  //   if (selectedItems.length === filteredChats.length && filteredChats.length > 0) {
  //     setSelectAll(true);
  //   } else {
  //     setSelectAll(false);
  //   }
  // }, [selectedItems, filteredChats]);
  //   const handleSelectAll = () => {
  //   if(selectionBox){
  //     setOpenselectionbox(false)
  //   }else{
  //     setOpenselectionbox(true)
  //   }
  //   if (!selectedItems.length) {
   
      
  //     setSelectedItems([...chatidz]); // Copy all chat IDs to selectedItems
  //   } else {
  
     

  //     setSelectedItems([]); // Clear selectedItems
  //   }
  //   setSelectAll(!selectAll); // Toggle selectAll state
  // };
  const handleSelectAll = () => {
    // Use functional updates to access the latest state
    setOpenselectionbox((prev) => {
      if (!prev) {
        // alert(chatidz)
        setSelectedItems([...chatidz]); // Copy all chat IDs to selectedItems
        return true; // Open selection box
      } else {
        setSelectedItems([]); // Clear selectedItems
        return false; // Close selection box
      }
    });
  };

  // useEffect(() => {
  //   if (selectAll) {
  //     // alert("if run");
  //     setOpenselectionbox(true);
  //     setSelectedItems([...chatidz]); // Copy all chat IDs to selectedItems
  //   } else {
  //     // alert("else run");
  //     setOpenselectionbox(false);
  //     setSelectedItems([]); // Clear selectedItems
  //   }
  // }, [selectionBox]);
  // const handleSelectAll = () => {
    // setLoading(true)
  //   if (selectAll) {
  //     setSelectedItems([]);
  //   } else {
  //     // alert("else run")
  //     setSelectedItems(chatidz);
  //     // setSelectedItems(filteredChats.map(item => item.id));
  //   }
  //   setSelectAll(!selectAll);
  // };

  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
  
      setSelectedItems(selectedItems.filter(itemId => itemId !== id));
    } else {
    
      setSelectedItems([...selectedItems, id]);
    }
  };
  const [modalValues, setModalValues] = useState({
    // list:  contactinfo?.list || '',
    // tags:  contactinfo?.tags || '',
    status: contactinfo?.status || '',
    address: '',
    notes: ''
  });
  const updateContact = async (e) => {
    setLoading(true)
    e.preventDefault()
   
    try{
      if (contactinfo.firstname.trim() === ''  || contactinfo.list.trim() === '') {
        // Display error message or handle error
        // For example, you can set an error state to display a message to the user
        alert('First name and list are required.');
        handlelistModalClose()
        return; // Stop further execution
      }
    }catch(error){
      // alert("except run")
      if (inputValues.firstname.trim() === ''  || inputValues.list.trim() === '') {
        // Display error message or handle error
        // For example, you can set an error state to display a message to the user
        alert('First name and list are required.');
        handlelistModalClose()
        return; // Stop further execution
      }
    }
    // Prepare data by filtering out empty values
    
  //   setContactifo(prevContactInfo => ({
  //     ...prevContactInfo,
  //     ...inputValues,
  //     ...modalValues
  //   }));
    const data = Object.keys(inputValues)
  .filter(key => inputValues[key].trim() !== '')
  .reduce((acc, key) => {
    acc[key] = inputValues[key];
    return acc;
  }, { "status": currentstatus });

  // setLoading(true)
    // Send the request to the Django view
    try {
      const response = await axios.post(`${API_BASE_URL}/api/update-contact`, 
      {data,
      userid:id,
      phone:headernumber,
      }
     
    );
    // if(!fetchingChats){
    //   setLoading(false)
    // }
    setInterval(() => {
      setLoading(false);
    }, 6000);
    setShowButton(false);
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };
 
  const handlelistModalChange = (e) => {
     setShowButton(true);
    const { name, value } = e.target;
    
    setInputValues(prevState => ({
      ...prevState,
      [name]: value
    }));

    
  };
   const handleeditModalInputChange = (event) => {
    const { id, value } = event.target;
    setContactifo((prevInfo) => ({
      ...prevInfo,
      [id]: value,
    }));
  };
  const handleUpdatesubmit = async (event) => {
    setLoading(true)
    event.preventDefault(); // Prevent the default form submission behavior

    // Gather all form data into an object
    const formData = {
      
      userid: id,
      _id: contactinfo.id,
      firstname: contactinfo.firstname,
      lastname: contactinfo.lastname,
      phone: contactinfo.phone,
      address: contactinfo.address,
      email: contactinfo.email,
      list: contactinfo.list,
      tags: contactinfo.tags,
      status: contactinfo.status,
      notes: contactinfo.notes,
    };
    
    try {
      const response = await fetch(`${API_BASE_URL}/api/update-crm-contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
     
      setCurrentstatus(contactinfo.status)
    setChats(prevChats => prevChats.map(chat => {
      if (chat.id === activechat) {
          return { ...chat, status: contactinfo.status };
      }
      return chat;
  }));
  setLoading(false)

  alert("Contact Updated Successfuly")
  handlecontacteditModalClose()

      // Handle successful response here (e.g., show a success message, close the modal, etc.)
    } catch (error) {
      // Handle error here (e.g., show an error message)
      setLoading(false)
    }
  };
  
  const handleModalChange = (event) => {
    setShowButton(true);
    const { name, value } = event.target;
   
    
    setModalValues({
      ...modalValues,
      [name]: value
    });
  };
  const handleModalSubmit = async (event) => {
    setClickHereVisible(false)
    event.preventDefault();
    try{
      if (contactinfo.phone.trim() === '' ) {
        // if (contactinfo.firstname.trim() === '' ||  contactinfo.list.trim() === '') {
        // Display error message or handle error
        // For example, you can set an error state to display a message to the user
        alert('Phone are required.');
        handlelistModalClose()
        handletagModalClose()
      handlestatusModalClose()
      handlenotesModalClose()
      handleModalClose()
        return; // Stop further execution
      }
    }catch(error){
      // alert("except run")
      if (inputValues.phone.trim() === '' ) {
        // if (inputValues.firstname.trim() === '' ||  inputValues.list.trim() === '') {
        // Display error message or handle error
        // For example, you can set an error state to display a message to the user
        alert('Phone  are required.');
        handletagModalClose()
      handlestatusModalClose()
      handlenotesModalClose()
      handleModalClose()
        return; // Stop further execution
      }
    }
   
    const data = Object.keys(modalValues)
    .filter(key => modalValues[key].trim() !== '')
    .reduce((acc, key) => {
      acc[key] = modalValues[key];
      return acc;
    }, {});
    try {
      const response = await axios.post(`${API_BASE_URL}/api/update-contact`, 
      {data,
      userid:id,
      phone:headernumber,
      }
     
    );
      // navigate('/crm')
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };
  // useEffect(() => {
  //   // Check if there's an active chat and chats are updated
  //   if (activeId && chats.length > 0) {
  //     // Find the updated chat
  //     const updatedChat = chats.find(chat => chat.id === activeId);
  //     // If the updated chat exists, trigger click event
  //     if (updatedChat) {
  //       alert(updatedChat)
  //       const chatElement = document.getElementById(updatedChat.id);
  //       if (chatElement) {
  //         chatElement.click();
  //       }
  //     }
  //   }
  // }, [chats, activeId]);

  // useEffect(() => {
  //   if (!initializeRef.current && phonenumbers.length > 0 && filteredChats.length > 0) {
  //     const firstPhoneNumber = phonenumbers[0];
  //     const firstChat = filteredChats[0];
  //     setActiveId(firstPhoneNumber.id);
  //     setActiveChat(firstChat.id);
  //     alert(firstPhoneNumber)
  //     initializeRef.current = true; // Prevent future initializations
  //   }
  // }, [phonenumbers, filteredChats]);
  // if (!initialized && phonenumbers.length > 0 && filteredChats.length > 0) {
  //   const firstPhoneNumber = phonenumbers[0];
  //   const firstChat = filteredChats[0];
  //   setActiveId(firstPhoneNumber.id);
  //   setActiveChat(firstChat.id);
  //   setInitialized(true);
  // }
  const handleInputChangeofaud = (e) => {
    const { id, value } = e.target;
    setoldAudience((prevAudience) => ({
      ...prevAudience,
      [id]: value,
    }));
  };
  const updateunRead = async (singleid) => {
    
   
    const confirmed = window.confirm(`Are you sure you want to Mark Unread`);
    if (confirmed) {
      
      const response = await axios.post(`${API_BASE_URL}/api/mark-unread`, { id:id,chats:selectedItems });
      const intervalId = setInterval(() => {
        setLoading(false)
            }, 5000); // Play every 3 seconds, adjust timing as needed
          
      // window.location.reload()
    }
};
const updatechatunRead = async (singleid) => {
    
  // alert(activechat)
const confirmed = window.confirm(`Are you sure you want to Mark Unread`);
if (confirmed) {
  setLoading(true)
  setLoading(true)
  const response = await axios.post(`${API_BASE_URL}/api/mark-unread`, { id:id,chats:[activechat] });
  const intervalId = setInterval(() => {
    setLoading(false)
        }, 5000); // Play every 3 seconds, adjust timing as needed
      
  // window.location.reload()
}
};
const updatechatRead = async (singleid) => {
    
    // alert(activechat)
  const confirmed = window.confirm(`Are you sure you want to Mark Read`);
  if (confirmed) {
    setLoading(true)
    setLoading(true)
    const response = await axios.post(`${API_BASE_URL}/api/mark-read`, { id:id,chats:[activechat] });
    const intervalId = setInterval(() => {
      setLoading(false)
          }, 5000); // Play every 3 seconds, adjust timing as needed
        
    // window.location.reload()
  }
};
  const updateRead = async (singleid) => {
    
    
    const confirmed = window.confirm(`Are you sure you want to Mark Read`);
    if (confirmed) {
      setLoading(true)
      setLoading(true)
      const response = await axios.post(`${API_BASE_URL}/api/mark-read`, { id:id,chats:selectedItems });
      const intervalId = setInterval(() => {
        setLoading(false)
            }, 5000); // Play every 3 seconds, adjust timing as needed
          
      // window.location.reload()
    }
};

const Markreadall = async () => {
    

  const confirmed = window.confirm(`Are you sure you want to Mark Read`);
  if (confirmed) {
    
    const response = await axios.post(`${API_BASE_URL}/api/mark-read-all`, { id:activeId });
    alert("Succesfuly Marked !")
    
    // window.location.reload()
  }
};


const deleteNote = async (note,contactid) => {
    // alert(note,contactid)
  
  const confirmed = window.confirm(`Are you sure you want to Delete`);
  if (confirmed) {
    
    const response = await axios.post(`${API_BASE_URL}/api/delete-note`, { contactid:contactid,note:note });
    // alert("Succesfuly Deleted !")
    
    setContactifo((prevContactInfo) => {
      return {
        ...prevContactInfo,
        notes: prevContactInfo.notes.filter((noteObj) => noteObj.note !== note),
      };
    });
    // window.location.reload()
  }
};
  const deleteChat = async (singleid) => {
    
    if(singleid.length > 2){
      const confirmed = window.confirm(`Are you sure you want to Delete`);
      if (confirmed) {
        const response = await axios.post(`${API_BASE_URL}/api/delete-chat`, { id:id,chats:singleid });
        alert("Succesfuly Deleted !")
        
        setChats(prevChats => prevChats.filter(chat => !singleid.includes(chat.id)));
      }
      return;
    }
    const confirmed = window.confirm(`Are you sure you want to Delete`);
    if (confirmed) {
      
      const response = await axios.post(`${API_BASE_URL}/api/delete-chat`, { id:id,chats:selectedItems });
      alert("Succesfuly Deleted !")
      setSelectedItems([])
      setMessages([])
      setContactifo([])
      setCurrentstatus('')
      setActiveChat('')
      setheaderConditionumber('')
      setChats(prevChats => prevChats.filter(chat => !selectedItems.includes(chat.id)));
      // window.location.reload()
    }
};
const deleteContact = async (singleid) => {

 
  const confirmed = window.confirm(`Are you sure you want to Delete the Contact?`);
  if (confirmed) {
    setLoading(true);
    setLoading(true);
    setLoading(true);
    const response = await axios.post(`${API_BASE_URL}/api/delete-contacts`, { ids:[singleid] });
    // alert("Succesfuly Deleted !")
    
    // setContactifo([])
    setTimeout(() => {
      setLoading(false);
      // handlecontactimportModalClose();
    }, 10000);
    // window.location.reload()
  }
};
  const handleStatusChange = (status) => {
    sendFilterToDjango(status);
    // const confirmed = window.confirm(`Are you sure you want to set   ${status}?`);
    // if (confirmed) {
    //     // Send the selected status to the Django view
    //     sendFilterToDjango(status);
    // }
};
const addlist = async (listname,event) => {
  // setLoading(true)
  event.preventDefault();

  try {
    // Send POST request to add a list
    const response = await axios.post(`${API_BASE_URL}/api/add-list`, { id, listname });
    
    setLoading(false)
    
    const newlist = {
      id: response.data,
      listname: listname, 
  
    };
    const lists = list ? [...list, newlist] : [newlist];
    setList(lists);
    handlelistModalClose(false);
    // setSelectedList(listname)
  } catch (error) {
    setLoading(false)

    console.error('Error adding list:');
  }
};
const addtag = async (id, tagname,description,event) => {
  // setLoading(true)
  // setTagsucc(false)
  event.preventDefault();

  try {
    // Send POST request to add a list
    const response = await axios.post(`${API_BASE_URL}/api/add-tag`, { id, tagname,description });
    
    setLoading(false)
    handletagModalClose()
    const newtag = {
      id: response.data,
      tagname: tagname, 
      description:description,
  
    };
    const taghs = tags ? [...tags, newtag] : [newtag];
    setTags(taghs);
  } catch (error) {
    setLoading(false)

    console.error('Error adding Tag:');
  }
};

const handleNotesubmission = async (e) => {
  // alert("clicked")
  setLoading(true)
    setLoading(true)
  e.preventDefault(); // Prevent default form submission behavior
    
   
    const formData = {
      userid: id,
      _id: contactinfo.id,
      firstname: contactinfo.firstname || "",
      lastname: contactinfo.lastname || "",
      phone: headernumber,
      address: contactinfo.address || "",
      email: contactinfo.email || "",
      list: contactinfo.list || "",
      tags: contactinfo.tags || "",
      status: contactinfo.status || "New", // Default status
      notes: notes || "",
    };
    setNotes("")
    fetch(`${API_BASE_URL}/api/add-crm-contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Assuming `notes` is a variable with the new note value
        // const notes = "This is the new note to be added"; // Replace this with your actual note value
        
        // Wait for 5 seconds before updating and closing
        const intervalId = setInterval(() => {
          setLoading(false);
    
          // Update the contact information with the new note
          // setContactifo((prevContactInfo) => ({
          //   ...prevContactInfo,
          //   notes: [
          //     ...(prevContactInfo.notes || []), // Retain existing notes or initialize as an empty array
          //     { note: notes, createdAt: new Date().toISOString() } // Add the new note with createdAt
          //   ]
          // }));
    
          // Close the modal
          handlecontacteditModalClose();
    
          clearInterval(intervalId); // Clear the interval to stop further executions
         
        }, 5000); // Adjust the timing as needed (5 seconds here)
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false); // Stop loading in case of an error
      });
    
 
};
const sendFilterToDjango = async (status) => {
    try {
        // Send the selected filter value to the Django view
        const response = await axios.post(`${API_BASE_URL}/api/set-chat-status`, { id:id,status,number:headernumber,tophoneid:activeId });
        setCurrentstatus(status)
        setContactifo(prevContactInfo => {
          // Clone the previous contact info
          let updatedContactInfo = { ...prevContactInfo };
          
          // Update the status field
          updatedContactInfo.status = status;
          
          return updatedContactInfo;
      });
      setModalValues(prevValues => ({
        ...prevValues,
        status: contactinfo?.status || '',
      }));
      // setChats
      setChats(prevChats => prevChats.map(chat => {
        if (chat.id === activechat) {
            return { ...chat, status: status };
        }
        return chat;
    }));
    } catch (error) {
        console.error('Error filtering data:', error);
    }
};
const submitForm = (e) => {
  e.preventDefault()
  const objectLength = Object.keys(contactinfo).length;

  const formData = {
    userid: id,
    _id: contactinfo.id,
    firstname: oldaudience.first_name || "",
    lastname: oldaudience.last_name || "",
    phone: headernumber,
    address: oldaudience.property_address || "",
    email: oldaudience.email || "",
    list: oldaudience.list || "",
    tags: oldaudience.tags || "",
    state: oldaudience.state || "",
    city: oldaudience.city || "",
    zip: oldaudience.zip || "",
    status: oldaudience.status || "New", // Default status
    notes: notes || "",
  };

  if (objectLength > 1) {
    const confirmed = window.confirm(
      `Are you sure you want to Overwrite data?`
    );
    if (!confirmed) {
      return; // Exit if not confirmed
    }
  }

  setLoading(true);

  fetch(`${API_BASE_URL}/api/add-crm-contact`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .then((data) => {
      setTimeout(() => {
        setLoading(false);
        handlecontactimportModalClose();
      }, 10000); // Adjust timing if necessary
    })
    .catch((error) => {
      console.error("Error saving data:", error);
      setLoading(false); // Ensure loading stops on error
    });
};

  if (!authenticated) {
    return null;
  } else {
    return (
      <Sidebar>
        {isGlobalLoading  && (
          <>
        <div class="loading-container">
    <img src={loadinggif} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
        {loading && (
          <>
        <div class="loading-container">
    <img src={loadinggif} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}

<Modal show={showimporoptionsmmodal} contentClassName="custom-modal-content" onHide={handleimportoptModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form  >

        <Modal.Body>
        <div className="text-center">
            <h4>SETTINGS</h4>
        </div>
        <div className="modal-body ">
           
            
              <div className="form-group" style={{textAlign:"center"}}>
             
               <Link  type="button"  onClick={() => handleeditModalOpen(numbername)} className="btn btn_primary save_btn" style={{ marginRight: '5px' }}>
        <img alt="" className="mr-3" />&nbsp;&nbsp;Edit Name <FaPenSquare></FaPenSquare>
    </Link>&nbsp;&nbsp;
    <Link type="button" onClick={() => Markreadall()} className="btn btn_primary save_btn" style={{ marginRight: '5px' }}>
        <img alt="" className="mr-3" />&nbsp;&nbsp;Mark Read  <FaReadme></FaReadme>
    </Link>
    
              </div>
              
          
          
          </div>
        </Modal.Body>
       
          </form>
      </Modal>

<Modal show={showcontactimportmodal}  backdrop="static" // Prevent closing by clicking outside the modal
        keyboard={false}  contentClassName="custom-modal-content" onHide={handlecontactimportModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form
  onSubmit={(e) => {
    submitForm(e)}}
>
      <Modal.Body>
        <div className="text-center">
          <h4>ADD CONTACT</h4>
        </div>
        <div className="modal-body">
          <div className='container mt-3'>
            <div className='row'>
              <div className='col-md-6'>
                <label className="text-white">First Name:</label>
                <input
                  className="form-control"
                  type="text"
                  
                  placeholder='First Name'
                  value={audience.first_name || ''}
                  id="firstname"
                  name="firstname"
                  onChange={(e) => setoldAudience({ ...audience, first_name: e.target.value })}
                  required
                />
              </div>
              <div className='col-md-6'>
                <label className="text-white">Last Name:</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder='Last Name'
                  value={audience.last_name || ''}
                  id="lastname"
                   name="lastname"
                   onChange={(e) => setoldAudience({ ...audience, last_name: e.target.value })}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <label className="text-white">Phone:</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  placeholder='Phone'
                  name="phone"
                  value={formatPhoneNumber(headernumber)}
                  id="phone"
                  

                  disabled
                />
              </div>
              <div className='col-md-6'>
                <label className="text-white">Property Address:</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  placeholder='Address'
                  value={audience.property_address}
                  id="address"
                  name="propertyaddress"
                  onChange={(e) => setoldAudience({ ...audience, property_address: e.target.value })}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <label className="text-white">Email:</label>
                <br />
                <input
                  className="form-control"
                  type="email"
                  placeholder='Email'
                  onChange={handleInputChangeofaud}
                  id="email"
                  name="email"
                />
              </div>
              <div className='col-md-6'>
                <label className="text-white">List:</label>
                <br />
                <select
                  id="list"
                  name="list"
                  className="form-select custom-dorpdown-toglle"
                  onChange={handleInputChangeofaud}
                  
                >
                  <option value="" >Select List</option>
                  {list.map(item => (
                    <option key={item.listname} value={item.listname}>{item.listname}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <label className="text-white">Tags:</label>
                <br />
                <select
                  id="tags"
                  name="tags"
                  className="form-select custom-dorpdown-toglle"
                  onChange={handleInputChangeofaud}
                  
                >
                  <option value="" >Select Tags</option>
                  {tags.map(item => (
                    
                    <option key={item.tagname} value={item.tagname}>{item.tagname}</option>
                  ))}
                </select>
              </div>
              <div className='col-md-6'>
                <label className="text-white">Status:</label>
                <br />
                <select
                  id="status"
                  name="status"
                  className="form-select custom-dorpdown-toglle"
                  onChange={handleInputChangeofaud}
                  
                >
                  <option value="" disabled>Select Status</option>
                  <option value="New">New</option>
                  <option value="Hot">Hot</option>
                  <option value="Warm">Warm</option>
                  <option value="Not Interested">Not Interested</option>
                  <option value="DNC">DNC</option>
                  <option value="Follow UP">Follow Up</option>
                  <option value="Prospecting">Prospecting</option>
                  <option value="Appointment Set">Appointment Set</option>
                  <option value="Make Offer">Make Offer</option>
                  <option value="Needs Analysis">Needs Analysis</option>
                </select>
              </div>
              <div className='col-md-12'>
                <label className="text-white">Notes:</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  placeholder='Notes'
                  onChange={(e) => setNotes(e.target.value)}
                  id="notes"
                  name="notes"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center mb-2">
        <button
          type="button"
          className="btn btn-secondary cancel_btn"
          onClick={handlecontactimportModalClose}
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="submit" className="btn btn-primary save_web_btn">
          Submit
        </button>
      </Modal.Footer>
    </form>

      </Modal>


  <Modal show={showcontacteditmodal} dialogClassName="modal-fullscreens" contentClassName="custom-modal-content" onHide={handlecontacteditModalClose} backdropClassName="custom-backdrop" >
 
        <Modal.Header closeButton>
        </Modal.Header>
        <form
  onSubmit={(e) => {
    e.preventDefault(); // Prevent default form submission behavior
    console.log( contactinfo)
    console.log( contactinfo.id)
    const formData = {
      userid: id,
      _id: contactinfo.id,
      firstname: contactinfo.firstname || "",
      lastname: contactinfo.lastname || "",
      phone: headernumber,
      address: contactinfo.address || "",
      email: contactinfo.email || "",
      list: contactinfo.list || "",
      tags: contactinfo.tags || "",
      status: contactinfo.status || "New", // Default status
      notes: notes || "",
      city: contactinfo.city || "",
      state: contactinfo.state || "",
      zip: contactinfo.zip || "",
    };
    setLoading(true)
    setLoading(true)
    fetch(`${API_BASE_URL}/api/add-crm-contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        const intervalId = setInterval(() => {
          setLoading(false)
          handlecontactimportModalClose();
              }, 5000); // Play every 3 seconds, adjust timing as needed
            
        // Close modal
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  }}
  >
      <Modal.Body>
        <div className="text-center">
          <h4>EDIT CONTACT</h4>
        </div>
        <div className="modal-body">
  <div className="container mt-3 d-flex">
    {/* Left Section */}
    <div className="w-60">
      <div className="row">
        <div className="col-md-6">
          <label className="text-white">First Name:</label>
          <input
            className="form-control"
            type="text"
            placeholder="First Name"
            value={contactinfo.firstname || ''}
            id="firstname"
            onChange={handleeditModalInputChange}
            
          />
        </div>
        <div className="col-md-6">
          <label className="text-white">Last Name:</label>
          <input
            className="form-control"
            type="text"
            placeholder="Last Name"
            value={contactinfo.lastname || ''}
            id="lastname"
            onChange={handleeditModalInputChange}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-6">
          <label className="text-white">Phone:</label>
          <input
            className="form-control"
            type="text"
            placeholder="Phone"
            value={formatPhoneNumber(headernumber)}
            id="phone"
            onChange={handleeditModalInputChange}
            disabled
          />
        </div>
        <div className="col-md-6">
          <label className="text-white">Property Address:</label>
          <input
            className="form-control"
            type="text"
            placeholder="Address"
            value={contactinfo.address || ''}
            id="address"
            onChange={handleeditModalInputChange}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-6">
          <label className="text-white mb-1">Email:</label>
          <input
            className="form-control"
            type="email"
            placeholder="Email"
            value={contactinfo.email || ''}
            id="email"
            onChange={handleeditModalInputChange}
          />
        </div>
        <div className="col-md-6">
          <label className="text-white mb-1">City:</label>
          <input
            className="form-control"
            type="text"
            placeholder="City"
            value={contactinfo.city || ''}
            id="city"
            onChange={handleeditModalInputChange}
          />
        </div>
        <div className="col-md-6">
          <label className="text-white mb-1">State:</label>
          <input
            className="form-control"
            type="text"
            placeholder="State"
            value={contactinfo.state || ''}
            id="state"
            onChange={handleeditModalInputChange}
          />
        </div>
        <div className="col-md-6">
          <label className="text-white mb-1">Zip:</label>
          <input
            className="form-control"
            type="txt"
            placeholder="Zip"
            value={contactinfo.zip || ''}
            id="zip"
            onChange={handleeditModalInputChange}
          />
        </div>
        <div className="col-md-6">
          <label className="text-white  mb-1" style={{display:"flex"}}>List: &nbsp;<button type="button" style={{fontSize: '14px'}} onClick={handlelistModalOpen}
                    class="add-num-btn text-white"><img src={add}></img></button></label>
          <select
            id="list"
            name="list"
            className="form-select custom-dorpdown-toglle"
            value={contactinfo.list || ''}
            onChange={handleeditModalInputChange}
          >
            <option value="" disabled>
              Select List
            </option>
            {list.map((item) => (
              <option key={item.listname} value={item.listname}>
                {item.listname}
              </option>
            ))}
          </select>
          
        </div>
        <div className="col-md-6">
          <label className="text-white mb-2" style={{display:"flex"}}>Tags: &nbsp;<button style={{fontSize: '14px'}}   onClick={handletagModalOpen}
                    type="button" class="add-num-btn text-white"><img src={add}></img></button></label>
          <select
            id="tags"
            name="tags"
            className="form-select custom-dorpdown-toglle"
            value={contactinfo.tags || ''}
            onChange={handleeditModalInputChange}
          >
            <option value="" disabled>
              Select Tags
            </option>
            {tags.map((item) => (
              <option key={item.tagname} value={item.tagname}>
                {item.tagname}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-1">
        
        <div className="col-md-6 mt-1">
          <label className="text-white mb-1">Status: </label>
          <select
            id="status"
            name="status"
            className="form-select custom-dorpdown-toglle"
            value={contactinfo.status || ''}
            onChange={handleeditModalInputChange}
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="New">New</option>
            <option value="Hot">Hot</option>
            <option value="Warm">Warm</option>
            <option value="Not Interested">Not Interested</option>
            <option value="DNC">DNC</option>
            <option value="Follow UP">Follow Up</option>
            <option value="Prospecting">Prospecting</option>
            <option value="Appointment Set">Appointment Set</option>
            <option value="Make Offer">Make Offer</option>
            <option value="Needs Analysis">Needs Analysis</option>
          </select>
        </div>
      </div>
    </div>

    {/* Right Section */}
    <div className="w-40 ps-3">
      <label className="text-white">Notes:</label>

      <div className="chat-history mb-1 ">
  <ul style={{    height: '150px',overflow:"scroll"}}>
  {contactinfo.notes && contactinfo.notes.length > 0 ? (
    contactinfo.notes.map((noteObj, index) => (
      <li key={index} className="clearfix user-msg mb-2">
        <div className="content-container">
          <div className="message-data">
           
          </div>
          <div className="message other-message float-right">
            <div>
              {noteObj.note || "No content available."} {/* Fallback for missing note */}
              <br />
            </div>
          </div>
        </div>
      </li>
    ))
  ) : (
    <li className="clearfix user-msg mb-2">
      <div className="content-container">
        <div className="message other-message float-right">
          <div>
            No notes available. {/* Fallback for an empty notes array */}
            <br />
          </div>
        </div>
      </div>
    </li>
  )}




   
  </ul>
 
</div>
<div className="msg-inputs msg-inputs-custom mt-2" style={{background:"none !important",padding:"0px !important"}}>
       
      
   
       <textarea className="msg-input" type="text" id="notes" name="notes" rows="3" placeholder="Write Note..."  onChange={(e) => setNotes(e.target.value)}/>
       <button className="send-btn" onClick={handleNotesubmission} name="send" id="send-btn"><FaPaperPlane /></button>
     </div>
      
    </div>
  </div>
</div>

      </Modal.Body>
      <Modal.Footer className="justify-content-center mb-2">
        <button
          type="button"
          className="btn btn-secondary cancel_btn"
          onClick={handlecontacteditModalClose}
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="submit" className="btn btn-primary save_web_btn">
          UPDATE
        </button>
      </Modal.Footer>
    </form>
   
      </Modal>
        {/* // editname modal */}
          <Modal show={showeditnamemodal} contentClassName="custom-modal-content" onHide={handleeditModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
         <form onSubmit={updatename}>
        <Modal.Body>
        <div className="text-center">
            <h4>EDIT NAME </h4>
        </div>
        <div className="modal-body ">
           
              <div className="form-group">
                {/* <label htmlFor="recipient-name" className="col-form-label mb-2">Property Name:</label> */}
                <input onChange={(e) => setNumberName(e.target.value)} required value={name} type="text" className="form-control" id="recipient-name"  />
              </div>
              
           
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleeditModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
         </form>
      </Modal>
        <Modal size="lg" show={showreplyemodal} contentClassName="custom-modal-content" onHide={handlereplyeModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
            <h4>QUICK REPLY </h4>
        </div>
     
        <div className="modal-body text-center">
            
            <table style={{width: '100%'}}>
              <thead>
                <th style={{float: 'inline-start'}}>Name</th>
                <th>Reply</th>
              </thead>
              {autoreplies.map((item, index) => (
              <tbody>
              
                <td style={{float:'left'}}>
                  <div  >{item.replyname}</div>
                </td>
                <td >
                <ul class="auto-replies text-white">
                <li className="text-white"
        
        // onClick={(event) => sendmessage(item.reply, event)}
        onClick={(event) => appendMessage(item.reply, event,item.media)}
      >
        {item.reply}
      </li>
                </ul>
              </td>
              <td><a href={item.media} target="_blank"> <img style={{width: '5rem'}} src={item.media}></img> </a></td>
              </tbody>
              ))}
            </table>
            {/* <ul class="auto-replies"> */}
{/*               
            {autoreplies ? (
  <ul>
    {autoreplies.map((item, index) => (
             

      <li 
        
        // onClick={(event) => sendmessage(item.reply, event)}
        onClick={(event) => appendMessage(item.reply, event)}
      >
        {item.reply}
      </li>
      
    ))}

  </ul>
) : (
  <div className="text-center text-white fs-6">
    No Reply Found <Link to="/quick_reply">Create New?</Link>
  </div>
)} */}

      {/* </ul> */}
              
           
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlereplyeModalClose} data-dismiss="modal" >Close</button>
            <button type="button" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer> */}
      </Modal>
      <Modal size="lg" show={shownewnumreplymodal} contentClassName="custom-modal-content" onHide={handlenewnumreplyeModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
            <h4>QUICK REPLY </h4>
        </div>
     
        <div className="modal-body text-center">
            <form >
              
            <table style={{width: '100%'}}>
              <thead>
                <th style={{float: 'inline-start'}}>Name</th>
                <th>Reply</th>
              </thead>
              {autoreplies.map((item, index) => (
              <tbody>
              
                <td style={{float:'left'}}>
                  <div  >{item.replyname}</div>
                </td>
                <td >
                <ul class="auto-replies text-white">
                <li className="text-white"
        
        // onClick={(event) => sendmessage(item.reply, event)}
        onClick={(event) => appendMessage(item.reply, event,item.media)}
      >
        {item.reply}
      </li>
                </ul>
                
              </td>
              <td><a href={item.media} target="_blank"> <img style={{width: '4rem'}} src={item.media}></img> </a></td>
              </tbody>
              ))}
            </table>
            {/* <ul class="auto-replies">
              
            {autoreplies ? (
  <ul>
    {autoreplies.map((item, index) => (
      <li 
      onClick={(event) => appendMessage(item.reply, event)}
        
        // onClick={(event) => newnumbernmessage(item.reply, event)}
      >
        {item.reply}
      </li>
    ))}

  </ul>
) : (
  <div className="text-center text-white fs-6">
    No Reply Found <Link to="/quick_reply">Create New?</Link>
  </div>
)}

      </ul> */}
              
            </form>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlereplyeModalClose} data-dismiss="modal" >Close</button>
            <button type="button" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer> */}
      </Modal>
  <Modal show={shownotesmodal} contentClassName="custom-modal-content" onHide={handlenotesModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={handleModalSubmit}>
        <Modal.Body>
        <div className="text-center">
            <h4>Add Note</h4>
        </div>
        <div className="modal-body ">
        
              <div className="form-group">
                {/* <label htmlFor="recipient-name" className="col-form-label mb-2">Property Name:</label> */}
                <input placeholder="Notes" type="text" name="notes" className="form-control" id="recipient-name" required  value={modalValues.notes} onChange={handleModalChange}  />
              </div>
              
        
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlenotesModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
        </form>
      </Modal>
      
      <Modal show={callingModal} contentClassName="custom-modal-content" onHide={handleModalOpenofcallingClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        {call && (<p className="fs-4"> Status : {callstatus}</p>)}
        </Modal.Header>
       
        <Modal.Body>
        <div className="container">
          <div className="row">

         <div className="col-md-6"  style={{ display: 'flex', flexDirection: 'column'}}>
         <div style={{ display: 'flex'}}><span class="">Dial : &nbsp;</span><input   style={{ '-moz-appearance': 'textfield' }}  disabled={callstatus.length > 0} placeholder="(615) 354 3859" type="text" value={inputValue.length > 1 ? formatPhoneNumber(inputValue) : inputValue} onChange={handleInputPhoneChange}  className="number-call-input "></input></div>
              
         {call && (
          <>
              <br></br>
              <ul style={{ padding: '0px 7px 0px 9px'}}>
    <li class="list-group-item mb-2 mt-4">{numbername}  (You)</li>
    <li className="list-group-item">
          {inputValue.length === 10 
            ? formatPhoneNumber(inputValue) 
            : headernumber}&nbsp;&nbsp;&nbsp;
          {callstatus !== 'Active' ? (
            <img style={{ width: '23px' }} src={calloading} alt="Call loading" />
          ) : (
            formatDuration(callDuration)
          )}
        </li>
    {/* <li class="list-group-item ">{headernumber}&nbsp;&nbsp;&nbsp;{callstatus !== "Active" &&  <img style={{    width: '23px'}} src={calloading} alt="Call loading" />} {callInterval}</li>
     */}
  </ul>
  
    <div style={{ display: 'flex', justifyContent: 'end', marginTop: 'auto' }}>
    <button
        className={`btn float-left ${isMicMuted ? 'btn-secondary' : 'btn-success'}`}
        style={{ width: '55px',margin:'0 auto' }}
        onClick={handleMicClick}
      >
        {isMicMuted ? (
          <FaMicrophoneSlash className="fs-5" />
        ) : (
          <FaMicrophone className="fs-5" />
        )}
      </button>

    <button class="btn btn-danger float-right" onClick={handleDecline} style={{width:'55px',margin:'0 auto'}}><FaPhone className="fs-5" style={{transform: 'rotate(225deg)'}}></FaPhone></button>
  </div>
  </>
   )}
          </div>
         
          <div className="col-md-6">
              <div class="container-dial">
      <div id="output"></div>
      <div className="row">
        <div className="digit" onClick={() => handleDigitPress('1')}>1</div>
        <div className="digit" onClick={() => handleDigitPress('2')}>2</div>
        <div className="digit" onClick={() => handleDigitPress('3')}>3</div>
      </div>
      <div className="row">
        <div className="digit" onClick={() => handleDigitPress('4')}>4</div>
        <div className="digit" onClick={() => handleDigitPress('5')}>5</div>
        <div className="digit" onClick={() => handleDigitPress('6')}>6</div>
      </div>
      <div className="row">
        <div className="digit" onClick={() => handleDigitPress('7')}>7</div>
        <div className="digit" onClick={() => handleDigitPress('8')}>8</div>
        <div className="digit" onClick={() => handleDigitPress('9')}>9</div>
      </div>
      <div className="row">
        <div className="digit"></div>
        <div className="digit" onClick={() => handleDigitPress('0')}>0</div>
        <div className="digit"></div>
      </div>
      <div className="row">
        <div className="digit"></div>
        <div className="digit" >        <button type="button" className="btn btn-success" onClick={startcall}  disabled={callstatus.length > 0}><FaPhone/></button>
        </div>
        <div className="digit"></div>
      </div>
      {/* <div class="botrow"><i class="fa fa-star-o dig" aria-hidden="true"></i>

        <i class="fa fa-long-arrow-left dig" aria-hidden="true"></i>
      </div> */}
    </div> 
          </div>
        </div>
        </div>
           
         
        </Modal.Body>
      
       
      </Modal>
      <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalOpenofphoneClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
       
        <Modal.Body>
        
        <div className="text-center">
            <h4>PLEASE CONNECT YOUR TELNYX ACCOUNT TO ACCESS THIS FEATURE</h4>
        </div>
        <div className="modal-body ">
            
        <div className="col-12" >
    <iframe width="440" height="299" src="https://www.youtube.com/embed/iSYeb4e-e18?si=jnZWJquIOKcHUS7L" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen=""></iframe>
  </div>
           
          </div>
        </Modal.Body>
      
       
      </Modal>
<Modal show={showsecondModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={handleModalSubmit}>
        <Modal.Body>
        <div className="text-center">
            <h4>Add Property</h4>
        </div>
        <div className="modal-body ">
          
              <div className="form-group">
                {/* <label htmlFor="recipient-name" className="col-form-label mb-2">Property Name:</label> */}
                <input placeholder="Property Address"  required value={modalValues.address} onChange={handleModalChange}  type="text" className="form-control" id="recipient-name" name="address"/>
              </div>
              
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showtagmodal} contentClassName="custom-modal-content" onHide={handletagModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        {/* <form onSubmit={handleModalSubmit}>

        <Modal.Body>
        <div className="text-center">
            <h4>Assign  Tags</h4>
            <Link to="/tags">Create</Link>
        </div>
        <div className="modal-body ">
            {tags  ?(
            
              <div className="form-group" style={{textAlign:"center"}}>
              <select id="list-select" name="tags" className="form-select custom-dorpdown-toglle" value={modalValues.tags} onChange={handleModalChange} >
        <option selected>Select Tag</option>
        {tags.map(item => (
          <option key={item.tagname} value={item.tagname}>{item.tagname}</option>
        ))}
      </select>
              
              </div>
              
          
            ):(
<div className="text-center text-white fs-6">
    No Tags Found <Link to="/data-management">Create New?</Link>
  </div>
            )}
          </div>
        </Modal.Body>
        {tags  &&(<Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handletagModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
        )}
          </form> */}
          <form onSubmit={(event) => addtag(id, tagname, description,event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE TAG</h4>
        </div>
        <div className="modal-body ">
            
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">Name:</label>
                <input type="text" placeholder="Tag Name" required onChange={(e) => setTagname(e.target.value)}  className="form-control" id="recipient-name"  />
              </div>
               <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Description:</label>
                <textarea type="text" name="type" className="form-control"  onChange={(e) => setDescription(e.target.value)}  />

              </div>
             
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handletagModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={loading}>{loading ? 'Saving....' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal>
  <Modal show={showlistmodal} contentClassName="custom-modal-content" onHide={handlelistModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        {/* <form onSubmit={(e) => updateContact(e)}>

        <Modal.Body>
        <div className="text-center">
            <h4>Assign to list</h4>
            <Link to="/data-management">Create</Link>

        </div>
        <div className="modal-body ">
            {list  ?(
            
              <div className="form-group" style={{textAlign:"center"}}>
              <select id="list-select" name="list" className="form-select custom-dorpdown-toglle" value={inputValues.list} onChange={handlelistModalChange} >
        <option value="" selected>Select List</option>
        {list.map(item => (
          <option key={item.listname} value={item.listname}>{item.listname}</option>
        ))}
      </select>
              
              </div>
              
          
            ):(
<div className="text-center text-white fs-6">
    No List Found <Link to="/data-management">Create New?</Link>
  </div>
            )}
          </div>
        </Modal.Body>
        {list  &&(<Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlelistModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
        )}
          </form> */}
          <form onSubmit={(event) => addlist(listname, event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE LIST</h4>
        </div>
        <div className="modal-body ">
            
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">List Name:</label>
                <input type="text" placeholder="List Name" className="form-control" required onChange={(e) => setListname(e.target.value)} value={listname} id="recipient-name"  />
              </div>
             
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlelistModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn"  disabled={loading}>{loading ? 'Saving....' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showstatusmodal} contentClassName="custom-modal-content" onHide={handlestatusModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={handleModalSubmit}>
        <Modal.Body>
        <div className="text-center">
            <h4>Add Status</h4>
        </div>
        <div className="modal-body ">
        
              <div className="form-group" style={{textAlign:"center"}}>
              {/* <Dropdown ref={dropdownRef} name="status" className="d-inline-block" show={dropdownOpen} onClick={() => setDropdownOpen(!dropdownOpen)} >
      <Dropdown.Toggle value={modalValues.status}  onChange={handleModalChange} variant="" name="status" className="custom-toggle" id=""  style={{ cursor: 'pointer' }}>
      Select Status
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <li className="mb-2" value="New"><FaHome className="fa-home-icon"  /> New</li>
      <li className="mb-2" value="Hot"><FaFire  className="fa-fire-icon"  /> Hot</li>
      <li className="mb-2" value="Warm"><FaSun className="fa-warm-icon"  /> Warm</li>
      <li className="mb-2" value="Not Interested"><FaThumbsDown className="fa-thumb-icon"/>Not Interested</li>
      <li className="mb-2" value="DNC"><FaBan className="fa-dnc-icon" /> DNC</li>
      <li className="mb-2" value="Follow UP"><FaHistory className="fa-history-icon" />  Follow UP</li>
                  </Dropdown.Menu>
    </Dropdown> */}
    <select id="list-select" name="status" className="form-select custom-dorpdown-toglle" value={modalValues.status} onChange={handleModalChange} >
        <option value="" selected>Select Status</option>
    
          <option  value="New">New</option>
          <option  value="Hot">Hot</option>
          <option  value="Warm">Warm</option>
          <option  value="Not Interested">Not Interested</option>
          <option  value="DNC">DNC</option>
          <option  value="Follow UP">Follow UP</option>
          <option value="Prospecting">Prospecting</option>
                  <option value="Appointment Set">Appointment Set</option>
                  <option value="Make Offer">Make Offer</option>
                  <option value="Needs Analysis">Needs Analysis</option>
    
        
      </select>
              </div>
              
           
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlestatusModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
        </form>
      </Modal>
<audio src={sound} ref={audioRef}></audio>
<audio src={DialTone} ref={DialToneRef}></audio>
<audio src={Hangup} ref={HangupRef}></audio>
<audio src={startcalltune} ref={startcallref}></audio>
<audio ref={remoteAudioRef} autoPlay></audio>
    <div className="row p-3">
    {/* <div className="col-2" style={{height:'450px',
   }}> */}
    <div className="col-2" >
        {/* <div className="row">
          
          <div className="col " style={{ padding: '0px',
    overflow: 'scroll' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <p style={{ margin: 0 }}>Inbox</p>
  <Link to="/numbers" style={{ textDecoration: 'none',    color: '#f9b840',
    fontWeight: '500' }}>+ Add Number</Link>
</div>
            <div className="input-group">
              <input
                type="text"
                className="form-control inpt_search"
                placeholder="Search"
                value={searchTerm}
            onChange={handleSearchChange}
              />
            </div>
            <hr />
            <ul
              style={{ listStyle: 'none', display: 'inline' }}
              className="inbox_number"
            >
              {phonenumbers.map((item) => (
            <li
              key={item.id}
              className={item.id === activeId ? 'active_chanel' : ''}
              onClick={() => handlePhoneNumberClick(item.id,id)}
            >
              <Link to={`/chats/${item.id}`}>
                <div style={{ display: 'flex' }}>
                  <p className="chatRoomName chat_room_2">
                    {item.name}
                    <br />
            
                    <div className="font-weight-500">{item.number}&nbsp;&nbsp;<span class="badge text-bg-danger">{item.read !== 0 && (item.read)}</span></div>
                  </p>
                </div>

              </Link>
              {item.id === activeId && (
                <FaPenSquare
                onClick={() => handleeditModalOpen(item.name)}
                style={{ cursor: 'pointer' }}
              />
              
              )}
            </li>
          ))}
              
            </ul>
            <nav>
              
            </nav>
          </div>
        </div> */}
      <div className="row" onScroll={handleScroll}>
      {/* <div className="row" > */}
            {/* <div className="col" style={{ padding: '0px', overflow: 'scroll' }}  ref={scrollRef}> */}
            <div className="col" style={{ padding: '0px', overflow: 'scroll' }}  ref={scrollRef}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ margin: 0 }}>Inbox</p>
                    <a href="/numbers" style={{ textDecoration: 'none', color: '#f9b840', fontWeight: '500' }}>+ Add Number</a>
                </div>
                {activeId && (
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control inpt_search"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                )}
                <hr />
                <ul style={{ listStyle: 'none', display: 'inline' }} onSubmit={() => {
    setLoading(true)}}  className="inbox_number">
                    {phonenumbers.map((item) => (
                        <li key={item.id} className={item.id === activeId ? 'active_chanel' : ''} onClick={(event) => handlePhoneNumberClick(event,item.id, id,item.number,item.name)}>
                            <a href={`/chats/${item.id}`}>
                                <div style={{ display: 'flex' }}>
                                    <p className="chatRoomName chat_room_2">
                                        {item.name}
                                        <br />
                                        <div className="font-weight-500">{item.number}&nbsp;&nbsp;<span className="badge text-bg-danger">{item.read !== 0 && (item.read)}</span></div>
                                    </p>
                                </div>
                            </a>
                          
                            {item.id === activeId && (
                              <>
                                {/* <FaPenSquare onClick={() => handleeditModalOpen(item.name)} style={{ cursor: 'pointer' }} /> */}
                                {/* <FaReadme onClick={() => Markreadall()} style={{ cursor: 'pointer' }} /> */}
                                <FaCog  onClick={() => handleimportoptModalOpen(item.name)}   style={{ cursor: 'pointer' }} />
                                
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
      </div>
      

            
      <div className="col-3">
      
          <div className="row">
            <div className="col">
                <div className="d-flex justify-content-between mb-4" >
                {(filteredChats.length > 0 || selectedOption) && (
                      <>
                    <div className="d-inline">
                  
                  
                   
                      <Dropdown     ref={dropdownRef} className="d-inline-block" show={dropdownOpen} onClick={() => setDropdownOpen(!dropdownOpen)}>
      <Dropdown.Toggle variant=""  className="custom-toggle" id=""  style={{ cursor: 'pointer',width:'fit-content' }}>
       {selectedOption || 'Filter'}
      </Dropdown.Toggle>

      <Dropdown.Menu>
     <li className="mb-2" onClick={() => handleStatusFilter('All')}>All</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Read')}>Read</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Unread')}>Unread</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Not Responded')}>Not Responded</li>
          <li className="mb-2" onClick={() => handleStatusFilter('New')}><FaHome className="fa-home-icon" /> New</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Hot')}><FaFire className="fa-fire-icon" /> Hot</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Warm')}><FaSun className="fa-warm-icon" /> Warm</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Follow UP')}><FaHistory className="fa-history-icon" /> Follow UP</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Not Interested')}><FaThumbsDown className="fa-thumb-icon" /> Not Interested</li>
          <li className="mb-2" onClick={() => handleStatusFilter('DNC')}><FaBan className="fa-dnc-icon" /> DNC</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Prospecting')}><FaBullhorn className="fa-fabull-icon" /> Prospecting</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Appointment Set')}><FaCalendarCheck className="fa-appointment-icon" /> Appointment Set</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Make Offer')}><FaDollarSign className="fa-dollar-icon" /> Make Offer</li>
          <li className="mb-2" onClick={() => handleStatusFilter('Needs Analysis')}><FaChartLine className="fa-chartline-icon" /> Needs Analysis</li>

      </Dropdown.Menu>
    </Dropdown>&nbsp;&nbsp;
    {selectedItems.length > 0 && (
      <>
        <Dropdown      className="d-inline-block">
      <Dropdown.Toggle variant=""  className="custom-toggle" id=""  style={{ cursor: 'pointer',width:'fit-content' }}>
       Action
      </Dropdown.Toggle>

      <Dropdown.Menu>
     {/* <li className="mb-2" >Action</li> */}
          <li className="mb-2" onClick={(e) => {updateRead("s") }}>Mark Read</li>
          <li className="mb-2" onClick={(e) => {updateunRead("s") }}>Mark Unread</li>
          
      </Dropdown.Menu>
    </Dropdown>
    {/* <button className="btn btn-outline-success"   type="button"  id="marking-btn">Read</button>&nbsp;&nbsp;
    <button className="btn btn-outline-danger"   type="button"  id="marking-btn">Unread</button> */}
    </>
  )}
                    </div>
                    </>
                     )}
                    {activeId && (
                      <>
                    <div className="mr-2 text-white" >
                    
                    <FaPhone class="mr-2" onClick={handleModalOpenofcalling} ></FaPhone>&nbsp;&nbsp;&nbsp;
                    <FaCommentDots className="cursor-pointer" onClick={handlenewmessgaeclick} />
                    {/* <FaCommentDots className="cursor-pointer" onClick={() => setAddnumdiv(prev => !prev)} /> */}
                    
                    </div>
                     </>
                    )}
                    
                </div>

             
              {selectedItems.length > 0 && (
        <div className="chat-selec">
          <div >
            <div style={{display:'contents'}}>
            <input 
            id="box"
              type="checkbox" 
              checked={selectAll} 
              onChange={handleSelectAll} 
            />
            <label htmlFor="box" id="chat-sel">-</label>
            </div>
            <span className="sel-num">{selectedItems.length} Selected </span>
            <FaTrash 
              style={{ float: 'right', color: '#f10505de', cursor: 'pointer' }}
             
              // onClick={deleteChat}
              onClick={(e) => {deleteChat("s") }}
            />
          </div>
        </div>
      )}
              <br></br> 
              <div className="div-col2" ref={scrollRef} onScroll={handleScroll}><ul style={{listStyle: 'none',display: 'inline',fontSize: '13px'}} class="inbox_number  ">               
     {filteredChats.length > 0 ? (
        filteredChats.map((item) => (
          <li
            key={item.id}
            className={item.id === activechat ? 'active_chanel mb-2 cursor-pointer' : 'mb-2 cursor-pointer'}
            onClick={() => handleClick(item.id, item.messages,item.number,item.status)}
          style={{justifyContent:'flex-start',border: item.number === highlightNumber ? '2px solid red' : 'none'}}>
            <div  style={{    width: '12px'}}>
              <input type="checkbox" className="li-input" checked={selectedItems.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} onClick={(e) => e.stopPropagation()}></input>
            </div>
            <a className="collapse-item lst_msg" style={{marginLeft:'10px'}}>
            <p className="chat-name chat_room_2">
  {(item.firstname && item.lastname) ? 
    `${item.firstname} ${item.lastname}` : 
    (item.firstname || item.lastname || item.number)}
</p>


              <div className="" style={{ display: 'flex' }}>
              {/* {item.latest_inbound_message !== "0"  ? (

  <span className={item.laststatus && item.laststatus.includes('failed') ? 'red' : ''}>
  {item.latest_inbound_message}
</span>


) : (
  <span>Draft Message </span>
)} */}
<>
{item.latest_inbound_message !== "0" ? (
  <span className={item.laststatus && item.laststatus.includes('failed') ? 'red' : ''}>
    {item.latest_inbound_message.call_leg_id ? (
      <>
        <FaPhone
          className={`lat-call-icon ${
            item.latest_inbound_message.declined || !item.latest_inbound_message.answered ? 'red' : 'green'
          }`}
        />
        <span>
          {item.latest_inbound_message.declined ? "Declined" : 
           item.latest_inbound_message.answered ? "Answered" : "Missed Call"}
        </span>
      </>
    ) : (
      item.latest_inbound_message
    )}
  </span>
) : (
  <span>Draft Message</span>
)}

{/* {item.latest_inbound_message !== "0" ? (
      <span className={item.laststatus && item.laststatus.includes('failed') ? 'red' : ''}>
        {item.latest_inbound_message === "CALLED" ? (
          <>
          <FaPhone className="lat-call-icon"/> <span>CALLED </span>
          </>
        ) : (
          item.latest_inbound_message
        )}
      </span>
    ) : (
      <span>Draft Message</span>
    )} */}
    </>
         
              </div>
              
            </a>
           <div style={{    margin: '0 auto',
    marginRight:'12px'}}>
           <span class="badge text-bg-danger">{item.unread_number !== 0 && (item.unread_number)}</span>&nbsp;&nbsp;
           {item.status === 'New' && <FaHome className="fa-home-icon cht-icon" />}
      {item.status === 'Hot' && <FaFire className="fa-fire-icon cht-icon" />}
      {item.status === 'Warm' && <FaSun className="fa-warm-icon cht-icon" />}
      {item.status === 'Not Interested' && <FaThumbsDown className="fa-thumb-icon cht-icon" />}
      {item.status === 'DNC' && <FaBan className="fa-dnc-icon cht-icon" />}
      {item.status === 'Follow UP' && <FaHistory className="fa-history-icon cht-icon" />}
      
      {item.status === 'Needs Analysis' && <FaChartLine className="fa-chartline-icon cht-icon" />}
      {item.status === 'Prospecting' && <FaBullhorn className="fa-fabull-icon cht-icon" />}
      {item.status === 'Appointment Set' && <FaCalendarCheck className="fa-appointment-icon cht-icon" />}
      {item.status === 'Make Offer' && <FaDollarSign className="fa-dollar-icon cht-icon" />}

      
           {/* {
  Object.keys(item.contactinfo).includes('firstname') &&
  Object.keys(item.contactinfo).includes('lastname') &&
  Object.keys(item.contactinfo).includes('list') ? (
    <>
      {item.status === 'New' && <FaHome className="fa-home-icon cht-icon" />}
      {item.status === 'Hot' && <FaFire className="fa-fire-icon cht-icon" />}
      {item.status === 'Warm' && <FaSun className="fa-warm-icon cht-icon" />}
      {item.status === 'Not Interested' && <FaThumbsDown className="fa-thumb-icon cht-icon" />}
      {item.status === 'DNC' && <FaBan className="fa-dnc-icon cht-icon" />}
      {item.status === 'Follow UP' && <FaHistory className="fa-history-icon cht-icon" />}
    </>
  ) : (
    <FaHome className="fa-home-icon cht-icon" />
  )
} */}


          
            
           
           </div>
            
            
          </li>
        ))
      ) : (
        <center><h4 className="chat-name chat_room_2 text-white">Inbox Cleared<br></br><p className="mt-2 text-white">You're All <span className="csv_detail">Caught Up!</span></p></h4></center>
      )}
    

    
           

           
</ul>
{/* <div id="floatingRectangle">Hi.</div> */}
</div>

        
            </div>
          </div>
      
      </div>
      <div className={`col-${colWidth}`}>
      {addnum && (
        <>
      <div className="row draftmessage">
            <div className="col">
             
            
             <div class="chat">
      <div class="chat-header clearfix">
        {/* <img src={user1} className="rounded-circle mr-4" style={{ width: '50px', height: '50px' }}></img> */}
        {/* <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" /> */}
        <div className="d-flex d-inline-block">
          <span className="mt-2" style={{width:'9%',fontSize:'16px'}} >To :</span>
          <input
            type="text"
            
            className="new-num-input"
            placeholder="(xxx) xxx xxxx"
            value={phoneNumber}
            onChange={handlePhoneInputChange}
          />
          
        <FaTimes
          className="clear-icon mt-2 fs-6"
          onClick={handleClearPhoneNumber}
          aria-label="Clear phone number"
        />
        </div>
        <div class="chat-about" onClick={handleShow}>
        {contactinfo && (
          <>
          <div class="chat-with">{contactinfo.firstname} {contactinfo.lastname}</div>
          <div class="chat-num-messages">{contactinfo.phone}</div>
          </>
        )}
        </div>
        
      </div>
      
      <div class="chat-history">
      <ul>
      {messages && messages.map((msg, index) => {
        const imageUrl = msg.media ? `${API_BASE_URL}/profile/${msg.media}` : '';
        // const imageUrl = msg.media ? `${process.env.PUBLIC_URL}/profile/${msg.media}` : '';
        return (
          <li key={index} className={msg.direction === 'outbound' ? 'clearfix user-msg' : 'clearfix admin-msg float-left'}>
            <div className="content-container">
              <div className="message-data">
              <span className="message-data-time">{convertToLocalTime(msg.createdAt)} {msg.direction === 'outbound' &&`${msg.status}`}</span>

                
              </div>
              <div className={`message ${msg.direction === 'outbound' ? 'other-message float-right' : 'my-message'}`}>
                
                {/* {msg.message}
                <br></br>
                {msg.media && msg.media.length > 0 && (
                  <a href={msg.media} target="_blank">
                  <img
                  style={{ height: '100px', width: '100px' }}
                  src={msg.media}
                  alt="Media"
                />
                </a>
                )} */}
                {msg.call_leg_id ? (
        // Render different code for calling messages
        <div>
  {msg.declined ? (
    <>
      <img style={{ width: '50px' }} src={missedcall} alt="Declined Call" />&nbsp;&nbsp;&nbsp;<span>Declined</span>
    </>
  ) : msg.answered ? (
    <>
      <span className="accept-call"><FaPhone className="accept-call-phone" /></span>&nbsp;&nbsp;&nbsp;<span>Answered</span>
    </>
  ) : (
    <>
      <img style={{ width: '50px' }} src={missedcall} alt="Missed Call" />&nbsp;&nbsp;&nbsp;<span>Missed Call</span>
    </>
  )}
</div>

      ) : (
        
        <div>
          {msg.message}
          <br></br>
          {msg.media && msg.media.length > 0 && (
            <a href={msg.media} target="_blank" rel="noopener noreferrer">
              <img
                style={{ height: '100px', width: '100px' }}
                src={msg.media}
                alt="Media"
              />
            </a>
          )}
        </div>
      )}
              </div>
              {/* <div className={`message ${msg.direction === 'outbound' ? 'other-message float-right' : 'my-message'}`}>
              
              
              {msg.message.length > 0 && (
                <>

                {msg.message}
                <br></br>
                </>
              )}

                
                {msg.media.length > 0 && (
                
               
                  <a href={msg.media} target="_blank">
                    <img
                    style={{ height: '100px', width: '100px' }}
                    src={msg.media}
                    alt="Media"
                  />
                  </a>
                
                )}
              </div> */}
              <div>
                <div>
              {/* {msg.media.length > 0 ? (
  
  <div className="message-img-status">
  <span className=""> {msg.direction === 'outbound' &&`${msg.status}`}</span>
  
  </div>
  ):(
    <div className="message-status">
  <span className=""> {msg.direction === 'outbound' &&`${msg.status}`}</span>
  
  </div>
  )
  
  } */}
  {msg.media && msg.media.length > 0 ? (
  <div className="message-img-status">
    <span className={
      (msg.status === 'dnc_failed' || msg.status === 'delivery_failed') && msg.direction === 'outbound' ? 'red' : ''
    } title={
      msg.status === 'dnc_failed' && msg.direction === 'outbound' ? 'Number is in DNC Manager' :
      msg.status === 'delivery_failed' && msg.direction === 'outbound' ? 'Delivery Failed' : ''
    }>
      {msg.status === 'dnc_failed' && msg.direction === 'outbound' ? 'Failed' :
      msg.status === 'delivery_failed' && msg.direction === 'outbound' ? 'Failed' : msg.status}
    </span>
  </div>
) : (
  <div className="message-status">
  <span className={
    (msg.status === 'dnc_failed' || msg.status === 'delivery_failed' || msg.status === 'Failed') && msg.direction === 'outbound' ? 'red' : ''
  }>
    {(msg.status === 'dnc_failed' || msg.status === 'delivery_failed' || msg.status === 'Failed') && msg.direction === 'outbound' ? 'Failed' : msg.status}
  </span>
  </div>  
    // <span className={
    //   (msg.status === 'dnc_failed' || msg.status === 'delivery_failed') && msg.direction === 'outbound' ? 'red' : ''
    // }>
    //   {msg.status === 'dnc_failed' && msg.direction === 'outbound' ? 'Failed' :
    //   msg.status === 'delivery_failed' && msg.direction === 'outbound' ? 'Failed' : msg.status}
    // </span>
  
)}

  </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
    <div ref={chatEndRef} />
        
      </div> 
    
      <form  onSubmit={(event) => newnumbernmessage(message,event)}>
      <div style={{display: 'flex',
    backgroundColor: '#26272d',
    paddingLeft: '20px'}}>
    {showProfilePic && (
        <>
          <div style={{ textAlign: 'center', marginTop: '10px', position: 'relative', display: 'inline-block' }}>
            <img src={showProfilePic} style={{ width: '100px', height: '100px' }} alt="Profile" />
            <button
              onClick={handleRemoveImage}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                backgroundColor: '#fab43d',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                cursor: 'pointer',
                width: '20px',
                height: '20px',
                textAlign: 'center',
                lineHeight: '20px',
              }}
            >
              X
            </button>
          </div>
          <br />
        </>
      )}
         {/* {showProfilePic && (
       <>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <img src={showProfilePic}  style={{ width: '100px', height: '100px' }} />
        </div>
        <br></br>
        </>
      )} */}
         </div>
      <div className="msg-inputs">
       
      
        <FaReply className="file-reply-icon" onClick={handlenewnumreplyeModalOpen} />&nbsp;&nbsp;
        <label htmlFor="file-input" className="file-input-icon" style={{ display: 'flex' }}>
          <FaPaperclip />
        </label>
        <input type="file" id="file-input" style={{ display: 'none' }} onChange={handleFileChange} accept="image/png, image/jpeg, image/jpg" />
        <textarea  id="textarea-messageing"  ref={textareaRef}style={{ resize: 'none'}}   className="msg-input" type="text" rows="3" placeholder="Message"  value={message} onChange={handleInput} />
        <button className="send-btn" type="submit" name="send" id="send-btn"><FaPaperPlane /></button>
      </div>
    </form>
    

    </div> 
   
    
  </div>
          </div>
          </>
      )}
      {!addnum && (
        <>
          <div className="row">
            <div className="col">
             
           
             <div class="chat">
      <div class="chat-header clearfix">
        {/* <img src={user1} className="rounded-circle mr-4" style={{ width: '50px', height: '50px' }}></img> */}
        {/* <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" /> */}
        
        <div class="chat-about" onClick={handleShow}>
        {contactinfo.firstname ?(
        
  <>
  
    <div class="chat-with">{contactinfo.firstname} {contactinfo.lastname}</div>
    <div class="chat-num-messages">{contactinfo.phone}</div>
  </>
) : (
  <>
     
     
     <div className="fs-6">
  {headernumber.length > 1 ? formatPhoneNumber(headernumber) : null}
</div>


  
  </>
)}

        </div>
        {headernumber &&(
        <div className="st-icon-chat" >
           {currentstatus === 'New' && <FaHome className="fa-home-icon" />}
  {currentstatus === 'Hot' && <FaFire className="fa-fire-icon" />}
  {currentstatus === 'Warm' && <FaSun className="fa-warm-icon" />}
  {currentstatus === 'Not Interested' && <FaThumbsDown className="fa-thumb-icon" />}
  {currentstatus === 'DNC' && <FaBan className="fa-dnc-icon" />}
  {currentstatus === 'Follow UP' && <FaHistory className="fa-history-icon" />}

  {currentstatus === 'Needs Analysis' && <FaChartLine className="fa-chartline-icon" />}
  {currentstatus === 'Appointment Set' && <FaCalendarCheck className="fa-appointment-icon" />}
  {currentstatus === 'Prospecting' && <FaBullhorn className="fa-fabull-icon" />}
  {currentstatus === 'Make Offer' && <FaDollarSign className="fa-dollar-icon" />}
          
  {/* <FaBookmark  title="Mark Read" className="mark-chat" onClick={(e) => {updatechatRead("s") }}></FaBookmark>&nbsp;       
  <FaRegBookmark  title="Mark Unread" className="unmark-chat" onClick={(e) => {updatechatunRead("s") }}></FaRegBookmark>   &nbsp;       */}
 

          



        <Dropdown className="d-inline-block" >
            <Dropdown.Toggle variant="" className="custom-toggle" id="">
                Status
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <li className="mb-2" onClick={() => handleStatusChange('New')}><FaHome className="fa-home-icon" /> New</li>
                <li className="mb-2" onClick={() => handleStatusChange('Hot')}><FaFire className="fa-fire-icon" /> Hot</li>
                <li className="mb-2" onClick={() => handleStatusChange('Warm')}><FaSun className="fa-warm-icon" /> Warm</li>
                <li className="mb-2" onClick={() => handleStatusChange('Not Interested')}><FaThumbsDown className="fa-thumb-icon" /> Not Interested</li>
                <li className="mb-2" onClick={() => handleStatusChange('DNC')}><FaBan className="fa-dnc-icon" /> DNC</li>
                <li className="mb-2" onClick={() => handleStatusChange('Follow UP')}><FaHistory className="fa-history-icon" /> Follow UP</li>
                <li className="mb-2" onClick={() => handleStatusChange('Prospecting')}><FaBullhorn className="fa-fabull-icon" /> Prospecting</li>
          <li className="mb-2" onClick={() => handleStatusChange('Appointment Set')}><FaCalendarCheck className="fa-appointment-icon" /> Appointment Set</li>
          <li className="mb-2" onClick={() => handleStatusChange('Make Offer')}><FaDollarSign className="fa-dollar-icon" /> Make Offer</li>
          <li className="mb-2" onClick={() => handleStatusChange('Needs Analysis')}><FaChartLine className="fa-chartline-icon" /> Needs Analysis</li>
            
            </Dropdown.Menu>
        </Dropdown>&nbsp;
        <Dropdown      className="d-inline-block">
      <Dropdown.Toggle variant=""  className="custom-toggle" id=""  style={{ cursor: 'pointer',width:'fit-content' }}>
       Action
      </Dropdown.Toggle>

      <Dropdown.Menu>
     {/* <li className="mb-2" >Action</li> */}
          <li className="mb-2" onClick={(e) => {updatechatRead("s") }}>Mark Read</li>
          <li className="mb-2" onClick={(e) => {updatechatunRead("s") }}>Mark Unread</li>
          <li className="mb-2"  onClick={(e) => { e.stopPropagation(); deleteChat(activechat); }}>Delete</li>
          
      </Dropdown.Menu>
    </Dropdown>
        &nbsp;<FaPhone className="fs-6" onClick={handleStartCall} />
  {/* &nbsp;<FaTrash className="fa-trash-icon fs-6" onClick={(e) => { e.stopPropagation(); deleteChat(activechat); }}/> */}

        </div>
        )}
      </div>
      
      <div class="chat-history" ref={chatContainerRef}>
      <ul>
      {messages && messages.map((msg, index) => {
        const imageUrl = msg.media ? `${process.env.PUBLIC_URL}/profile/${msg.media}` : '';
        return (
          <li key={index} className={msg.direction === 'outbound' ? 'clearfix user-msg' : 'clearfix admin-msg float-left'}>
            <div className="content-container">
              <div className="message-data">
                <span className="message-data-time">{convertToLocalTime(msg.createdAt)} </span>
              </div>
              <div className={`message ${msg.direction === 'outbound' ? 'other-message float-right' : 'my-message'}`}>
                
                {/* {msg.message}
                <br></br>
                {msg.media && msg.media.length > 0 && (
                  <a href={msg.media} target="_blank">
                  <img
                  style={{ height: '100px', width: '100px' }}
                  src={msg.media}
                  alt="Media"
                />
                </a>
                )} */}
                {msg.call_leg_id ? (
        // Render different code for calling messages
        <div>
  {msg.declined ? (
    <>
      <img style={{ width: '50px' }} src={missedcall} alt="Declined Call" />&nbsp;&nbsp;&nbsp;<span>Declined</span>
    </>
  ) : msg.answered ? (
    <>
      <span className="accept-call"><FaPhone className="accept-call-phone" /></span>&nbsp;&nbsp;&nbsp;<span>Answered</span>
    </>
  ) : (
    <>
      <img style={{ width: '50px' }} src={missedcall} alt="Missed Call" />&nbsp;&nbsp;&nbsp;<span>Missed Call</span>
    </>
  )}
</div>

      ) : (
        // Render the default code for non-calling messages
        <div>
          {msg.message}
          <br></br>
          {msg.media && msg.media.length > 0 && (
            <a href={msg.media} target="_blank" rel="noopener noreferrer">
              <img
                style={{ height: '100px', width: '100px' }}
                src={msg.media}
                alt="Media"
              />
            </a>
          )}
        </div>
      )}
              </div>
              
            </div>
            {
  msg.status === 'dnc_failed' ? (
    <FaInfoCircle className="red fs-6 mt-3 mr-2" style={{ marginRight: '12px' }} title="Number is in DNC Manager"></FaInfoCircle>
  ) : msg.status === 'delivery_failed' ? (
    <FaInfoCircle className="red fs-6 mt-3 mr-2" style={{ marginRight: '12px' }} title={msg.reason}></FaInfoCircle>
  ) : msg.status === 'sending_failed' ? (
    <FaInfoCircle className="red fs-6 mt-3 mr-2" style={{ marginRight: '12px' }} title={msg.reason}></FaInfoCircle>
    ): msg.status === 'failed' ? (
    <FaInfoCircle className="red fs-6 mt-3 mr-2" style={{ marginRight: '12px' }} title={msg.reason}></FaInfoCircle>
  ) : null
}

            {/* { msg.status === 'dnc_failed' && (<FaInfoCircle className="red fs-6 mt-3 mr-2" style={{marginRight:'12px'}} title="Number is in DNC Manager"></FaInfoCircle>)} */}

<div className="">
{/* {msg.media && msg.media.length > 0 ? (
  
<div className="message-img-status">
<span className={  msg.status === 'dnc_failed' && msg.direction === 'outbound' ? 'red' : '' }  title={msg.status === 'dnc_failed' && msg.direction === 'outbound' ? 'Failed' :"Number is in DNC Manager"}>
        {msg.status === 'dnc_failed' && msg.direction === 'outbound' ? 'Failed' : msg.status}
      </span>
</div>
):(
  <div className="message-status">
 <span className={  msg.status === 'dnc_failed' && msg.direction === 'outbound' ? 'red' : '' } >
        {msg.status === 'dnc_failed' && msg.direction === 'outbound' ? 'Failed' : msg.status}
      </span>
</div>
)

} */}
{msg.media && msg.media.length > 0 ? (
  <div className="message-img-status">
    <span className={
      (msg.status === 'dnc_failed' || msg.status === 'delivery_failed' || msg.status === 'sending_failed') && msg.direction === 'outbound' ? 'red' : ''
    } title={
      msg.status === 'dnc_failed' && msg.direction === 'outbound' ? 'Number is in DNC Manager' :
      (msg.status === 'delivery_failed' || msg.status === 'sending_failed') && msg.direction === 'outbound' ? 'Failed' : ''
    }>
      {(msg.status === 'dnc_failed' || msg.status === 'delivery_failed' || msg.status === 'sending_failed') && msg.direction === 'outbound' ? 'Failed' : msg.status}
    </span>
  </div>
) : (
  <div className="message-status">
  <span className={
  (msg.status === 'dnc_failed' || msg.status === 'delivery_failed' || msg.status === 'failed') && msg.direction === 'outbound' ? 'red' : ''
}>
  {(msg.status === 'dnc_failed' || msg.status === 'delivery_failed' || msg.status === 'failed') && msg.direction === 'outbound' ? 'Failed' : msg.status}
</span>

    {/* <span className={
      (msg.status === 'dnc_failed' || msg.status === 'delivery_failed' || msg.status === 'sending_failed') && msg.direction === 'outbound' ? 'red' : ''
    }>
      {(msg.status === 'dnc_failed' || msg.status === 'delivery_failed' || msg.status === 'sending_failed') && msg.direction === 'outbound' ? 'Failed' : msg.status}
    </span> */}
  </div>
)}


</div>
          </li>
        );
      })}
    </ul>
       
        
      </div> 
      {activechat && (
          <>
          <form onSubmit={(event) => sendmessage(message, event)}>
         <div style={{display: 'flex',
    backgroundColor: '#26272d',
    paddingLeft: '20px'}}>
         {/* {showProfilePic && (
       <>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <img src={showProfilePic}  style={{ width: '100px', height: '100px' }} />
        </div>
        <br></br>
        </>
      )} */}
      {showProfilePic && (
        <>
          <div style={{ textAlign: 'center', marginTop: '10px', position: 'relative', display: 'inline-block' }}>
            <img src={showProfilePic} style={{ width: '100px', height: '100px' }} alt="Profile" />
            <button
              onClick={handleRemoveImage}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                backgroundColor: '#fab43d',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                cursor: 'pointer',
                width: '20px',
                height: '20px',
                textAlign: 'center',
                lineHeight: '20px',
              }}
            >
              X
            </button>
          </div>
          <br />
        </>
      )}
         </div>
      <div className="msg-inputs">
       
      
        <FaReply className="file-reply-icon" onClick={handlereplyeModalOpen} />&nbsp;&nbsp;
        <label htmlFor="file-input" className="file-input-icon" style={{ display: 'flex' }}>
          <FaPaperclip />
        </label>
        <input type="file" id="file-input" style={{ display: 'none' }} onChange={handleFileChange} accept="image/png, image/jpeg, image/jpg" />
        <textarea  id="textarea-messageing"  ref={textareaRef} style={{ resize: 'none', }}  className="msg-input" type="text" rows="3"   value={message} onChange={handleInput} />
        <button className="send-btn" type="submit" name="send" id="send-btn"><FaPaperPlane /></button>
      </div>
      
    </form>
      {/* <form  onSubmit={(event) => sendmessage(message,event)}>
      <div class="msg-inputs">
      <FaReply className="file-reply-icon" onClick={handlereplyeModalOpen}></FaReply>&nbsp;&nbsp;
      <label for="file-input" className="file-input-icon" style={{display:'flex'}}>
  
  <FaPaperclip></FaPaperclip>
</label>
<input type="file" id="file-input" style={{display: 'none'}}></input>

      <input class="msg-input" type="text" rows="3" placeholder="Message" required value={message} onChange={(e) => setMessage(e.target.value)}  />
      <button class="send-btn" type="submit" name="send" id="send-btn" ><FaPaperPlane></FaPaperPlane></button>
    </div>
    </form> */}
    </>
      )}
      {/* <div class="input-area">
					<div class="input-wrapper">
						<input type="text" value=""></input>
						<i class="fa fa-smile-o"></i>
						<i class="fa fa-paperclip"></i>
					</div>
					<input type="button" value="Submit" class="send-btn"></input>
				</div> */}
      {/* <div style={{display: 'inline'}} class="chat-message clearfix">
    <i class="fa fa-file-o"></i><textarea name="message-to-send" id="message-to-send" placeholder="Type your message" rows="3"></textarea>
    <button>Send</button> */}
{/* </div> */}

    </div> 
   
    
  </div>
          </div>
        </>
        )}
      </div>

      { activechat && (
        <div className={`col-3  animated-column`} style={{marginTop: '-2%', transition: 'transform 0.9s', transform: isHidden ? 'translateX(50%)' : 'none' }}>
     <ul className="nav nav-pills mt-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link-switch btn btn-primary  ${activeTab === 'CRM' ? 'active-tab' : ''}`}
            id="pills-home-tab"
            type="button"
            onClick={() => handleTabClick('CRM')}
            aria-selected={activeTab === 'CRM'}
          >
            CRM
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link-switch  btn btn-primary ${activeTab === 'Audience' ? 'active-tab' : ''}`}
            id="pills-profile-tab"
            type="button"
            onClick={() => handleTabClick('Audience')}
            aria-selected={activeTab === 'Audience'}
          >
            Audience
          </button>
        </li>
      </ul>
{/* <div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">...</div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
</div> */}  
    {/* //   <div className={`col-3 mt-3 animated-column`} style={{ transition: 'width 0.5s' }}> */}
    {activeTab === 'Audience' && (
      <>
      {/* <br></br> */}
      {audience && audience.first_name   ? (
        <div>
            
             <li className="dt-li mt-2">
  <div  style={{ display: 'flex' }}className={`col-auto ${audience.firstname ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left' }} ><FaUser />&nbsp;First name:</span>
    
    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{audience.first_name}</p>
    
  </div>
</li>

<li className="dt-li mt-2">
  <div  style={{ display: 'flex' }} className={`col-auto ${audience.lastname ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left',fontSize: '13px' }} ><FaUser />&nbsp;&nbsp;Last name:</span>

    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{audience.last_name}</p>
  
  </div>
</li>
        <li className="dt-li mt-2">
  <div style={{ display: 'flex' }} className={`col-auto ${audience.address ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left' }} ><FaAddressBook/>&nbsp;&nbsp;Address:</span>
    
    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{audience.property_address}</p>
 
  </div>
</li>
<li className="dt-li mt-2">
  <div  style={{ display: 'flex' }} className={`col-auto ${audience.lastname ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left',fontSize: '13px' }} ><FaBuilding />&nbsp;&nbsp;City:</span>

    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{audience.city}</p>
  
  </div>
</li>
<li className="dt-li mt-2">
  <div  style={{ display: 'flex' }} className={`col-auto ${audience.lastname ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left',fontSize: '13px' }} ><FaAddressBook />&nbsp;&nbsp;State:</span>

    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{audience.state}</p>
  
  </div>
</li>
<li className="dt-li mt-2">
  <div style={{ display: 'flex' }} className={`col-auto ${audience.lastname ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left',fontSize: '13px' }} ><FaAddressBook />&nbsp;&nbsp;Zip:</span>

    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{audience.zip}</p>
  
  </div>
</li>
<li className="dt-li mt-2">
  <div style={{ display: 'flex' }} className={`col-auto ${audience.lastname ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left',fontSize: '13px' }} ><FaInbox />&nbsp;&nbsp;Phone:</span>

    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{formatPhoneNumber(headernumber)}</p>
  
  </div>
</li>
{/* <span>SDSDS{contactinfo.ph }</span> */}
<center>
<button  onClick={handlecontactimportModalOpen} className="btn btn-primary text-center mt-3" style={{ fontSize: '14px !important' }}>
      
       
          Import To CRM

      </button>
      </center>
{/* {contactinfo && !contactinfo.phone &&(
  <>
<center>
<button  onClick={handlecontactimportModalOpen} className="btn btn-primary text-center mt-3" style={{ fontSize: '14px !important' }}>
      
       
          Import To CRM

      </button>
      </center>
      </>
  )} */}
      </div>
  
        
        
        ):(
          <center>
            <br></br>
            <span className="text-center mt-5">No Data Available</span></center>
        )};
        </>
      )};
    {activeTab === 'CRM' && (
      <div>
 
       
        {/* <div className="col-auto d-flex align-items-center">
          <img src={user1} className="rounded-circle mr-4" style={{ width: '50px', height: '50px' }} alt="User Icon" />
          <div>
            <p className="mb-0 nme-cn">Chris Lucas</p>
            <p className="mb-0">.</p>
          </div>
          <div><button onClick={handleHide} className="btn btn-outline-secondary ml-auto">
            <span aria-hidden="true">&times;</span>
          </button>
          </div>
        </div> */}
     
        {contactinfo && (
          <>
         
        <li className="dt-li mt-2">
  <div className={`col-auto ${contactinfo.firstname ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left' }} ><FaUser />&nbsp;First name:</span>
    {contactinfo.firstname ? (
    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{contactinfo.firstname}</p>
    ):(
      <></>
    //   <input
    //   type="text"
    //   placeholder="Jhon"
    //   name="firstname"
    //   id="contact-info-input"
    //   className="form-control" required
    //   value={inputValues.firstname}
    //               onChange={handleInputChange}
    // />
    )}
  </div>
</li>
       
<li className="dt-li mt-2">
  <div className={`col-auto ${contactinfo.lastname ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left',fontSize: '13px' }} ><FaUser />&nbsp;&nbsp;Last name:</span>
    {contactinfo.lastname ? (
    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{contactinfo.lastname}</p>
  ):(
    <></>
    // <input
    // type="text"
    // name="lastname"
    // placeholder="Jhon"
    // id="contact-info-input"
    // className="form-control"
    // value={inputValues.lastname}
    // onChange={handleInputChange}
  // />
  )}
  </div>
</li>

<li className="dt-li mt-2">
  <div className="col-auto d-flex align-items-center">
    <span style={{ textAlign: 'left' }} ><FaPhone />&nbsp;&nbsp;Phone:</span>
    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{formatPhoneNumber(headernumber)}</p>

  </div>
</li>
<li className="dt-li mt-2">
 <div className="col-auto d-flex align-items-center">
   <span style={{ textAlign: 'left' }}><FaHome/>&nbsp;&nbsp;Address:</span>
   <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{contactinfo.address}</p>
 </div>
 </li>
 {/* <span style={{ textAlign: 'left' }}><FaHome/>Notes:</span> */}
 {contactinfo.notes && contactinfo.notes.length > 0 ? (
  <div className="chat-history mb-1 note-coll" style={{ color: "white",listStyleType: "none", padding: 0  }}>
    <ul style={{ height: '150px', overflow: "scroll" }}>
      {contactinfo.notes.map((noteObj, index) => (
        <li key={index} className="clearfix user-msg mb-2">
          <div className="content-container">
            <div className="message-data">
              {/* You can add message metadata here if needed */}
            </div>
            <div className="message other-message float-right">
              <div>
                {noteObj.note} {/* Display the 'note' property */}
                <br />
              </div>
            </div>
            <div className="del-note mt-1">
              <div>
              <FaTrash 
              style={{ float: 'right', color: '#f10505de', cursor: 'pointer' }}
             
              // onClick={deleteChat}
              onClick={(e) => {deleteNote(noteObj.note,contactinfo.id) }}
            />
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
) : (
  <div className="chat-history mb-1 note-coll" style={{ color: "white" }}>
    <ul style={{ height: '150px', overflow: "scroll" }}>
      <li className="clearfix user-msg mb-2">
        <div className="content-container">
          <div className="message other-message float-right">
            <div>
              No notes available. {/* Fallback for an empty notes array */}
              <br />
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
)}

 <div id="msg-inputs-customs" className="msg-inputs " style={{background:"none !important",padding:"0px !important"}}>
       
      
   
       <input className="msg-input" type="text" id="notes" name="notes" rows="3" placeholder="Write Note..."  onChange={(e) => setNotes(e.target.value)}/>
       <button className="send-btn" onClick={handleNotesubmission} name="send" id="send-btn"><FaPaperPlane /></button>
     </div>
      
<center>
<button  onClick={handlecontacteditModalOpen} className="btn btn-primary text-center mt-1 mr-2" style={{ fontSize: '14px !important' }}>
      
       
          View More +

      </button>&nbsp;&nbsp;&nbsp;
      <button  onClick={handlecontacteditModalOpen} className="btn btn-success text-center mt-1 ml-2" style={{ fontSize: '14px !important' }}>
      
       
          Edit

      </button>&nbsp;&nbsp;&nbsp;
        {contactinfo.id && (
          <button   onClick={(e) => {deleteContact(contactinfo.id) }} className="btn btn-danger text-center mt-1 ml-2" style={{ fontSize: '14px !important' }}>
      
       
      Delete

  </button>
        )}
      
     
       
      


      </center>
{/* <li className="dt-li mt-2">
  <div className={`col-auto ${contactinfo.email ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left' }} ><FaInbox/>&nbsp;&nbsp;Email:</span>
    {contactinfo.email ? (
      
    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{contactinfo.email}</p>
  ):(
    <input
    type="email"
    name="email"
    placeholder="Jhon@email.com"
    id="contact-info-input"
    className="form-control"
    value={inputValues.email}
                  onChange={handleInputChange}
  />
  )}
  </div>
</li> */}
{/* {contactinfo.tags && contactinfo.tags.length > 0 ? (
<li className="dt-li mt-2">
  <div className={`col-auto ${contactinfo.tags ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left' }} ><FaTags/>&nbsp;&nbsp;Tags:</span>
   
    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{contactinfo.tags}</p>
    </div>
    </li>
  ):(
    <li class="add-num-li mt-2"   style={{display: 'flex',padding:'6px'}}>
               
                    <div style={{display:'flex',width: '100%'}} >
                    Tags:&nbsp;
                    <select
                  id="tags"
                  name="tags"
                  className="form-select custom-dorpdown-toglle"
              
                  value={inputValues.tags} 
                  onChange={handlelistModalChange} 
                 
                   style={{width:'115px',fontSize:'13px',width: '100% !important'}}
                >
                  <option value="" disabled>Select Tags</option>
                  {tags.map(item => (
                    <option key={item.tagname} value={item.tagname}>{item.tagname}</option>
                  ))}
                </select>&nbsp;
                <button style={{width: '100%'}} onClick={handletagModalOpen}
                    class="add-num-btn text-white"><img src={add}></img>&nbsp;&nbsp;Add New</button>
                 </div>
                </li>
 
  
  )} */}
 
{/* 
{contactinfo.list ? (
      <li className="dt-li mt-2">
        <div className="col-auto d-flex align-items-center">
          <span style={{ textAlign: 'left' }}><FaList/>&nbsp;&nbsp;List:</span>
          <p className="" style={{ marginLeft: 'auto' }}>{contactinfo.list}</p>
        </div>
      </li>
    ) : (
<li class="add-num-li mt-2" style={{display: 'flex',padding:'6px'}}>

                    <div style={{display:'flex',width: '100%'}}>
                    <span>List: </span>&nbsp;&nbsp;
                    <select
                  id="list"
                  name="list"
                  className="form-select custom-dorpdown-toglle"
                  value={inputValues.list}
                  
                  onChange={handlelistModalChange}
                  style={{width:'115px',fontSize:'13px',width: '100% !important'}}
                >
                  <option value="" disabled>Select List</option>
                  {list.map(item => (
                    <option key={item.listname} value={item.listname}>{item.listname}</option>
                  ))}
                </select>&nbsp;&nbsp;
                <button style={{width: '100%'}} onClick={handlelistModalOpen}
                    class="add-num-btn text-white"><img src={add}></img>&nbsp;&nbsp;Add New</button></div>
                    
                </li>
    )} */}
{/* 
{contactinfo.status ? (
      <li className="dt-li mt-2">
        <div className="col-auto d-flex align-items-center">
          <span style={{ textAlign: 'left' }}><FaIcons/>&nbsp;&nbsp;Status:</span>
          <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{contactinfo.status}</p>
        </div>
      </li>
    ) : (        
<li onClick={handlestatusModalOpen} class="add-num-li mt-2" style={{display: 'flex',padding:'6px'}}>

<Link  ><button
class="add-num-btn"><img src={add}></img></button></Link>
<div style={{display:'flex'}} >
Add Status</div>

</li>
    )} */}
</>
        )}
     
        {/* {contactinfo.address ? (
 <li className="dt-li mt-2">
 <div className="col-auto d-flex align-items-center">
   <span style={{ textAlign: 'left' }}><FaHome/>&nbsp;&nbsp;Address:</span>
   <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{contactinfo.address}</p>
 </div>

        ):(
       
          <input
    type="text"
    name="address"
    placeholder="Property Address"
    id="contact-info-input"
    className="form-control"
    value={inputValues.address}
                  onChange={handleInputChange}
  />
   </li>
          // <li onClick={handleModalOpen} class="add-num-li mt-2" style={{display: 'flex',padding:'6px'}}>
      
          // <Link  ><button
          //     class="add-num-btn"><img src={add}></img></button></Link>
          //     <div style={{display:'flex'}} >
          //     Add a Property</div>
              
         
        )} */}
        {/* <li className="dt-li mt-2">
  <div className={`col-auto ${contactinfo.address ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left' }} ><FaInbox/>&nbsp;&nbsp;Address:</span>
    {contactinfo.address ? (
    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{contactinfo.address}</p>
  ):(
    <input
    type="text"
    name="address"
    placeholder="Property Address"
    id="contact-info-input"
    className="form-control"
    value={inputValues.address}
                  onChange={handleInputChange}
  />
  )}
  </div>
</li> */}
        {/* {contactinfo.notes ? (
          <li className="dt-li mt-2">
          <div className="col-auto d-flex align-items-center">
            <span style={{ textAlign: 'left' }}><FaNotesMedical/>&nbsp;&nbsp;Notes:</span>
            <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{contactinfo.notes}</p>
          </div>
         </li>
        ):(
          <li onClick={handlenotesModalOpen} class="add-num-li mt-2" style={{display: 'flex',padding:'6px'}}>
      
      <Link  ><button
          class="add-num-btn"><img src={add}></img></button></Link>
          <div style={{display:'flex'}} >
          Add Note</div>
          
      </li>
        )} */}
         {/* <li className="dt-li mt-2">
  <div className={`col-auto ${contactinfo.notes ? 'd-flex align-items-center' : 'align-items-center'}`}>
    <span style={{ textAlign: 'left' }} ><FaInbox/>&nbsp;&nbsp;Note:</span>
    {contactinfo.notes ? (
    <p className="chatRoomName chat_room_2" style={{ marginLeft: 'auto' }}>{contactinfo.notes}</p>
  ):(
    <input
    type="text"
    name="notes"
    placeholder="Notes"
    id="contact-info-input"
    className="form-control"
    value={inputValues.notes}
                  onChange={handleInputChange}
  />
  )}
  </div>
  </li> */}
      {clickHereVisible && (
      <div className="text-center text-white fs-6 mt-4">
         {/* {hasOnlyStatusField ? (
      <button onClick={(e) => updateContact(e)} className="btn btn-primary" style={{fontSize: '14px !important'}}>Add To CRM</button>
    ) : (
      <button onClick={(e) => updateContact(e)} className="btn btn-primary" style={{fontSize: '14px !important'}}>Update</button>
    )} */}
         {/* {
  Object.keys(contactinfo).length === 3 ? (
    <button onClick={(e) => updateContact(e)} className="btn btn-primary" style={{fontSize: '14px !important'}}>Add To CRM</button>
  ) : (
    <button onClick={(e) => updateContact(e)} className="btn btn-primary" style={{fontSize: '14px !important'}}>Update</button>
  )
} */}
{showButton && (
       <button onClick={updateContact} className="btn btn-primary" style={{ fontSize: '14px !important' }}>
       {contactinfo && Object.keys(contactinfo).includes('firstname') && Object.keys(contactinfo).includes('lastname') && Object.keys(contactinfo).includes('list')
         ? 'Update'
         : 'Add To CRM'}
     </button>
     
      )}

        </div>
    )}

</div>
        )}  
      
      </div>
        )}
        
    </div>


       
      </Sidebar>
    );
  }

}

export default Phone;
